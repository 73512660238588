import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import * as bootstrap from "bootstrap";
import * as $AB from "jquery";
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as htmlToPdfmake from 'html-to-pdfmake';
import { DatePipe } from '@angular/common';
import { log } from 'console';

@Component({
  selector: 'app-user-history',
  templateUrl: './user-history.component.html',
  styleUrls: ['./user-history.component.scss'],
  providers: [DatePipe]

})
export class UserHistoryComponent implements OnInit {
  addTourForm: FormGroup;
  public SubCats: any;
  today: Date = new Date();
  formattedDate: string;
  allData: any;
  allDates: any;
  allCoupan: any;
  allOrder: any = {};
  tourID: any = {};
  webID: any;
  allTour: any;
  getData: any;
  allTourdate: any;
  selectebatchID: any;
  batchID: any;
  selectebatchPrice:any;
  payment_status: string = '';
  pan_card: string = '';
  //appliedCoupon: string = '';
  gstNumber: any;
  cusGSTNo: string = '';
  public cuscompanyName : string ='';
  public cuscompanyAddress : string = '';
  private user_id: string = localStorage.getItem('id');
  bookingNote: string = '';
  public allCheckoutTourData: any;
  public Object = Object;
  public multipleBoarded: any = [];
  public finalPrice: number = 0;
  public totalexclusivevalue: number = 0;
  FinalTourPrice: number = 0;
  //public tourAllData: any = {};
  public packages: any;
  public rooms: any;
  public boardings: any;
  public allTourData: any;
  public allOtherCharges: any;
  public readOnly: boolean = false;
  public isAuthLoading: boolean = false;
  public isAuthDisabled: boolean = false;
  public basicPrice: number = 0;
  public packPrice: number = 0;
  public roomPrice: number = 0;
  public boardPrice: number = 0;
  public no_of_traveller: number = 0;
  public totalPrice: number = 0;
  public bookingpricecust: number = 0;
  public amtWithTransactionalCharge: number;
  public isPartialPay: boolean = false;
  public amtWithCGST: number;
  public amtWithSGST: number;
  public amtWithIGST: number;
  public amtWithTCS: number;
  public totaltcsigst: number;
  public totaltcscgstsgst: number;
  public totalAmountToPaid: number;
  public totalAmountToPaidexclusive: number;
  FinalBooking: boolean = false;
  FinalBookingcustomized : boolean = false;
  FinalBookingpancard : boolean = false;
  couponmessage: boolean = false;
  public appliedCoupon: string = '';
  public discountedPriceByCoupon: number = 0;
  public isCouponApplied: boolean = false;
  public isCouponNotApplied: boolean = false;
  tourid: any;
  childprice: any;
  gst: string = 'inclusive';
  tcs: string = 'inclusive';
  infantprice: any;
  singlachildprice: any;
  singlainfentprice: any;
  public bikeopt: any;
  public packTypeSelected: string;
  public packBikePrice: number = 0;
  public allPackData: any[] = [];
  public allRoomData: any[] = [];
  public totalTraveller: number = 0;
  public totalTravellercustomized: number = 0;
  public finaltotalTraveller: number = 0;
  public numberofchild: number = 0;
  public numberofinfant: number = 0;
  public customizedpackageprice: number = 0;
  public customizedroomprice: number = 0;
  boarding_point: any
  startDate: string; // You can use Date type if you prefer
  endDate: string;   // You can use Date type if you prefer
  minStartDate: string; // Minimum start date
  public toltalchidprice: number = 0;
  public toltalinfantprice: number = 0;
  public selectedTour: any = '';
  packagePrices: number[] = [];
  roomPrices: number[] = [];
  public ifTourNotSelected: any = false;
  tourtype: any;
  kidsValue: any = '';
  infantValue: string = '';
  packageValues: number[] = new Array(this.allPackData.length).fill(0);
  roomValues: number[] = new Array(this.allRoomData.length).fill(0);
  numbercustomizedpackage: number[] = [];
  numbercustomizedroom: number[] = [];
  public idForPackage: any;
  public couponRes: any;
  sleeperPrice: number = 0;
  ac3Price: number = 0;
  ac2Price: number = 0;
  ac1Price: number = 0;
  flightPrice: number = 0;
  singalboardingprice: number = 0;
  customizedpackagetotaluser: any;
  customizedroomuser: any
  dataArray: any[] = [];
  dataArrayroom: any[] = [];
  boardingPointPricesEntered = {
    singal: false,
    sleeper: false,
    ac3: false,
    ac2: false,
    ac1: false,
    flight: false
  };
  boardingPointPrices: number = 0;
  boardingPointType: any
  enteredPrices: { boardingPoint: string, price: number }[] = [];
  priceEntered: any;
  logoImgInDateURL: string;
  order: any;
  n: number;
  totalPayAmt: number;
  totalpaidAddon: any;
  selectebatchNumber: any;

//public appliedCoupon: string = '';
  @ViewChild('one') d1: ElementRef;
  @ViewChild('dateSchedule') dateSchedule: ElementRef;
  @ViewChild('package') package: ElementRef;
  @ViewChild('solo') solo: ElementRef;
  @ViewChild('share') share: ElementRef;
  @ViewChild('roomShare') roomShare: ElementRef;
  @ViewChild('boardingPt') boardingPt: ElementRef;
  @ViewChild('boardingMulti') boardingMulti: ElementRef;
  @ViewChild('numberOfTraveller') numberOfTraveller: ElementRef;
  private toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
  seleactegst: any;
  seleactetcs: any;
  public amtWithCGSTNew: number;
  public amtWithSGSTNew: number;
  public amtWithTCSNew: number;
  amtWithIGSTNew: number;
  totaltax: number;
  newprice: number;
  calculatenewprie: number;
  newFinalPrice: string;
  isButtonDisabled: boolean;
  tour: any;
  subtotal: any;
  cgst: any;
  igst: any;
  tcsgst: any;
  sgst: any;
  removecoupan: any;



  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe
  ) {this.startDate = '';
  this.endDate = '';
  this.formattedDate = this.datePipe.transform(this.today, 'dd-MM-yyyy') || '';
  // Set the minimum start date to today's date
  this.setMinStartDate();}
      setMinStartDate() {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = today.getFullYear();
        this.minStartDate = `${yyyy}-${mm}-${dd}`;
      }
     minEndDate: string;
    updateMinEndDate() {
      if (this.startDate) {
          const selectedStartDate = new Date(this.startDate);
          const nextDay = new Date(selectedStartDate);
          nextDay.setDate(selectedStartDate.getDate() + 1);
          const formattedNextDay = nextDay.toISOString().split('T')[0];
          this.minEndDate = formattedNextDay; 
          if (this.endDate < this.minEndDate) {
              this.endDate = '';
          }
      }
    }

    updateEndDate() {
      if (this.startDate) {
          const endDate = new Date(this.startDate);
          endDate.setDate(endDate.getDate() + this.allTourdate?.nightorday);
          this.endDate = endDate.toISOString().split('T')[0]; // Format as yyyy-mm-dd
      }
    }

  ngOnInit(): void {
    // this.gst = 'inclusive'
    // this.tcs = 'inclusive'
    //console.log(this.formattedDate)
    this.addTourForm = new FormGroup({
      tour_id: new FormControl(),
      travel_date: new FormControl(),
      //travel_packageType: new FormControl(),
      //travel_boarding_point: new FormControl(),
      // travel_no_of_traveler: new FormControl(),
      // finalPrice: new FormControl(),
      status: new FormControl(),
    });
    this.addTourForm = this.formBuilder.group({
      tour_id: ['', [Validators.required]],
      travel_date: ['', [Validators.required]],
      //travel_packageType: ['', [Validators.required]],
      //travel_no_of_traveler: ['', [Validators.required]],
      //travel_boarding_point: ['', [Validators.required]],
      //finalPrice: ['', [Validators.required]],
      status: [false],
    });
    this.webID = this.route.snapshot.paramMap.get('id');
    this.editBoard(this.webID);
    //this.board();
    //this.activeTour();
    this.appliedCharges();
    this.CoupanList();
    this.invoiceLogoImgToDataURL();
    
    //this.getTotalSelectedRoom()
  }

  appliedCharges() {
    this.apiService.orderList().subscribe((res: any) => {
      // console.log(res);
      if (res.success) {
        this.allOtherCharges = res.data[0];
        // this.calculateAllExtraCharges();
      } else {
        // console.error(res);
      }
    });
  }
  

  getBoardingPrice(getBoardingData: any) {
    // console.log(getBoardingData)
    const allBoadingDataWithPrices = JSON.parse(getBoardingData);
    //console.log(allBoadingDataWithPrices)
    const BoardPrice = allBoadingDataWithPrices.filter(
      (board: any) =>
        board.bp === this.allCheckoutTourData?.travel_boarding_point?.boarding
    )[0]?.bp_price;
    this.allCheckoutTourData.travel_boardingPrice =
      BoardPrice.length === 1
        ? BoardPrice[0].singleBoardPrice
        : Object.values(
            BoardPrice.filter(
              (boardPrice: any) =>
                Object.keys(boardPrice)[0] ===
                this.allCheckoutTourData?.boardingType
            )[0]
      )[0];
  }

  CoupanList() {
    this.apiService.activeCoupanList().subscribe((res: any) => {
      // console.log(res, 'heeeeeeee');
      this.allCoupan = res.data;

    });
  }

  getAllStates() {
    this.spinner.show();
    this.apiService.getStates().subscribe((res: any) => { 
      const getStateData = res.filter(data => data.iso2 === this.allData.state);
      [this.allData.stateData] = getStateData;
      this.spinner.hide();
    });
  }

  getAllPackageData
    (data: any) {
      this.apiService.getAllPack().subscribe((res: any) => {
        if (res.success) {
          this.packages = res.data;
          for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < this.packages.length; j++) {
              if (data[i].packType === this.packages[j].package_type)
                data[i].id = this.packages[j]._id;
            }
          }
          // console.error(data);
          this.allOrder.packageType_price = data;
          const nonBikePack = data.filter((pack) => (pack.packPrice !== 'solo-bike' && pack.packPrice !== 'share-bike'));
          //console.log(data,'dattaa', nonBikePack);
          if (nonBikePack.length)
          this.allPackData.push(...nonBikePack.map((pack) => {return {...pack, type: 'normal', persons: 0}}));
          for(let data of this.allPackData){
            this.numbercustomizedpackage.push(0);
          }
          this.reorderPackage(this.allPackData);
          //console.log(this.allPackData)
        } else {
          console.error(res);
        }
      });
    }

    getAllRoomShareData(data: any) {
      //console.log(data);
      this.apiService.AllRooms().subscribe((res: any) => {
        if (res.success) {
          this.rooms = res.data;
          for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < this.rooms.length; j++) {
              if (data[i].roomType === this.rooms[j].room_name)
                data[i].id = this.rooms[j]._id;
            }
          }
          this.allOrder.roomSharing_price = data;
          this.allRoomData.push(...data.map(room => {return {...room, persons: 0}}));
          for(let data of this.allRoomData){
            this.numbercustomizedroom.push(0);
          }
          this.allRoomData.sort((a,b) => (+a.roomPrice > +b.roomPrice) ? 1 : ((+b.roomPrice > +a.roomPrice) ? -1 : 0));
        } else {
          console.error(res);
        }
      });
    }

    getPackageData(data: string) {
      const id = { id: data };
      this.apiService.getPackId(id).subscribe((res: any) => {
        if (res.success) {
          //console.error(res,'All Package');
          this.allCheckoutTourData.travel_packageType = res.getData.package_type;
            //this.getAllBikePackageDataID(this.allCheckoutTourData.travel_packageType);
            let packageType_price = JSON.parse(this.allTourData.packageType_price);
            this.allCheckoutTourData.travel_packagePrice = packageType_price.filter(
              (pack: any) =>
                pack.packType === this.allCheckoutTourData.travel_packageType
            )[0]?.packPrice;
          // if(this.allCheckoutTourData.travel_packageType === 'Solo bike' || this.allCheckoutTourData.travel_packageType === 'Shared bike'){
          //   this.getAllBikePackageDataID(this.allCheckoutTourData.travel_packageType);
          // }else{
          //   let packageType_price = JSON.parse(this.allTourData.packageType_price);
          //   this.allCheckoutTourData.travel_packagePrice = packageType_price.filter(
          //     (pack: any) =>
          //       pack.packType === this.allCheckoutTourData.travel_packageType
          //   )[0]?.packPrice;
          // }
        } else {
          console.error(res);
        }
      });
    }

    getAllBikePackageDataID(type: string) {
      const bikeId = type === 'Solo bike' ? this.allCheckoutTourData.travel_soloPackageType : this.allCheckoutTourData.travel_sharedPackageType;
      const id = { id: bikeId };
      this.apiService.getBikeId(id).subscribe((res: any) => {
        //console.log(res);
        if (res.success) {
          this.allCheckoutTourData.travel_bikeType = res.getData.bike_name;
          let bikeType_price = type === 'Solo bike' ? JSON.parse(this.allTourData.soloType_price) : JSON.parse(this.allTourData.shareType_price);
            let bikeData = bikeType_price.filter(
              (bike: any) =>
                type === 'Solo bike' ? bike.soloType === this.allCheckoutTourData.travel_bikeType : bike.sharedType === this.allCheckoutTourData.travel_bikeType
            );
            this.allCheckoutTourData.travel_bikePrice = type === 'Solo bike' ? bikeData[0]?.soloPrice : bikeData[0]?.sharedPrice;
        } else {
          console.error(res);
        }
      });
    }

    getAllRoomData(data: string) {
      const id = { id: data };
      this.apiService.getRoomId(id).subscribe((res: any) => {
        if (res.success) {
          //console.log(this.allTourData, this.allCheckoutTourData);
          this.allCheckoutTourData.travel_roomSharing = res.getData.room_name;
          let roomName_price = JSON.parse(this.allTourData.roomSharing_price);
          this.allCheckoutTourData.travel_roomPrice = roomName_price.filter(
            (room: any) =>
              room.roomType === this.allCheckoutTourData.travel_roomSharing
          )[0]?.roomPrice;
          //console.log(this.allTourData, this.allCheckoutTourData)
        } else {
          console.error(res);
        }
      });
    }

  // getAllBikePackageData(data: any, type: string) {
  //   this.apiService.AllBikes().subscribe((res: any) => {
  //     console.log(res, data);

  //     if (res.success) {
  //       this.bikeopt = res.data;
  //       for (let i = 0; i < data.length; i++) {
  //         for (let j = 0; j < this.bikeopt.length; j++) {
  //           if (type === "solo" && data[i].soloType === this.bikeopt[j].bike_name) {
  //             data[i].id = this.bikeopt[j]._id;
  //           } else if (type === "shared" && data[i].sharedType === this.bikeopt[j].bike_name) {
  //             data[i].id = this.bikeopt[j]._id;
  //           }
  //         }
  //       }
  //       if (type === "solo") {
  //         this.allOrder.soloType_price = data;
  //         this.allPackData.push(...data.map(bike => {return {id: bike.id, packType: bike.soloType, type: 'Solo', packPrice: bike.soloPrice, persons: 0}}));
  //       } else if (type === "shared") {
  //         this.allOrder.shareType_price = data;
  //         this.allPackData.push(...data.map(bike => {return {id: bike.id, packType: bike.sharedType, type: 'Shared', packPrice: bike.sharedPrice, persons: 0}}));
  //       }
  //     } else {
  //       console.error(res);
  //     }
  //   });
  // }


  getAllBoardingData(data: any) {
    this.apiService.getAllBoarding().subscribe((res: any) => {
      //console.log(res,'lll')
      if (res.success) {
        this.boardings = res.data;
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < this.boardings.length; j++) {
            if (data[i].bp === this.boardings[j].boarding)
              data[i].id = this.boardings[j]._id;
          }
        }
        // console.error(data);
        this.allOrder.boarding_point_price = data;
        //console.log(data)
        return data;
      } else {
        console.error(res);
      }
    });
  }
    
  onTourSelect(type){
    this.allTour = []
    this.allTourdate = []
     console.log(type)
     this.tourtype = type
     this.tourtypenormalcustomized()
     this.spinner.show();
  }

  tourtypenormalcustomized() {
    let dataobject = {
       tour_type : this.tourtype
     }
     this.apiService.tourtypenormalcustomized(dataobject).subscribe((res: any) => {
      this.spinner.show();
       console.log(res, 'heeeeeeee');
       if(res.success == true){
        this.allTour = res.data;
      //   this.batchID = this.allTour[0].select_batch[0]._id
      //  console.log(this.batchID)
        Swal.fire({
          text: res.message,
          icon: 'success',
        });
       }else{
        Swal.fire({
          text: res.message,
          icon: 'error',
        });
       }
       this.spinner.hide();
      
     });
  }

  onCategorySelect(id) {
    let dataForForm = {
      id: id,
    };
    //console.log(dataForForm,'hiiiiiiiiii');
    // this.apiService.orderID(dataForForm).subscribe((res: any) => {
    //   console.log(res.getData, 'devvvvvvvvvvvvvvvvvv');
    //   this.allOrder = res.getData;
    //   this.allOrder.start_date = JSON.parse(this.allOrder.start_date);
    //   this.allOrder.end_date = JSON.parse(this.allOrder.end_date);
    //   this.allOrder.dateArr = [];
    //   for (let i = 0; i < this.allOrder.start_date.length; i++) {
    //    if ((new Date()).getTime() < (new Date(this.allOrder.start_date[i])).getTime())
    //     this.allOrder.dateArr.push({
    //       startDate: this.allOrder.start_date[i],
    //       endDate: this.allOrder.end_date[i],
    //     });
    //   }
    //   this.allOrder.dateArr.sort(function(a: any,b: any){
    //     return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    //   });
    //   if (this.allOrder.dateArr.length === 0) {
    //       Swal.fire({
    //         title: 'No schedule available for this tour',
    //         text: "Please contact us for more details",
    //         icon: 'error',
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Ok',
    //       });
    //     }
    //   this.allOrder.no_of_alltraveler = Array(+this.allOrder.no_of_traveler).fill(0).map((x, i) => (i + 1));
    //   this.totalPrice = this.basicPrice = +this.allOrder?.basicAmount;

    //   const allPackPriceData = JSON.parse(this.allOrder.packageType_price);
    //   if (this.allOrder.soloType_price.length && JSON.parse(this.allOrder.soloType_price)?.length) {
    //     allPackPriceData.unshift({ packType: 'Solo bike', packPrice: 'solo-bike' });
    //   }
    //   this.getAllBikePackageData(JSON.parse(this.allOrder.soloType_price), "solo");
    //   if (this.allOrder.shareType_price.length && JSON.parse(this.allOrder.shareType_price)?.length) {
    //     allPackPriceData.unshift({ packType: 'Shared bike', packPrice: 'share-bike' });
    //   }
    //   this.getAllBikePackageData(JSON.parse(this.allOrder.shareType_price), "shared");

    //   this.getAllPackageData(allPackPriceData);
    //   this.getAllRoomShareData(JSON.parse(this.allOrder.roomSharing_price));
    //   this.getAllBoardingData(JSON.parse(this.allOrder.boarding_point_price));

    //   // console.log(this.allOrder.roomSharing_price);
    //   // if (this.allOrder.packageType_price.length)
    //   //   this.getAllPackageData(JSON.parse(this.allOrder.packageType_price));
    //   // if (this.allOrder.roomSharing_price.length)
    //   //   this.getAllRoomShareData(JSON.parse(this.allOrder.roomSharing_price));
    //   // this.getAllBoardingData(JSON.parse(this.allOrder.boarding_point_price));
    // });
    this.idForPackage = dataForForm.id;
  }

  handleClick() {
    if (this.selectedTour == '') {
      Swal.fire({
        text: "Please select a tour.",
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok',
      });
    } else {
   
      this.isButtonDisabled = true;
  
      this.ifTourNotSelected = true;
  
      // this.onCategorySelect(this.idForPackage)
      this.apiService.orderIDbooking({ id: this.idForPackage }).subscribe((res: any) => {
        if (res.getData.length != 0) {
          // console.log(res, 'devvvvvvvvvvvvvvvvvv');
          this.tourID = res.getData._id;
          this.allTourdate = res.getData;
          console.log(this.allTourdate,">>>>");
          
          // console.log(this.allTourdate.domesticorInternational);
          this.selectebatchID = res.getData.select_batch[0]._id;
          this.selectebatchNumber = res.getData.select_batch[0]?.batchNumber;
          this.allOrder = res.getData.select_batch[0];
          this.seleactegst = res.getData.select_batch[0];
          this.seleactetcs = res.getData.select_batch[0];
          // console.log(this.seleactegst.gst);
          this.childprice = res.getData.select_batch[0].child_price;
          this.infantprice = res.getData.select_batch[0].infant_price;


          // this.subtotal = res.getData.pricetourWithoutTaxes



          // console.log(this.subtotal,'>>>>>>>>>>>>>');
          
  
          if (this.allTourdate.tourtype == 'normal') {
            this.allDates = res.Datedata;
            this.allDates.start_date = JSON.parse(this.allDates.start_date);
            this.allDates.end_date = JSON.parse(this.allDates.end_date);
            this.allDates.dateArr = [];
            for (let i = 0; i < this.allDates.start_date.length; i++) {
              if ((new Date()).getTime() < (new Date(this.allDates.start_date[i])).getTime()) {
                this.allDates.dateArr.push({
                  startDate: this.allDates.start_date[i],
                  endDate: this.allDates.end_date[i],
                });
              }
            }
            this.allDates.dateArr.sort((a: any, b: any) => {
              return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
            });
            if (this.allDates.dateArr.length === 0) {
              Swal.fire({
                title: 'No schedule available for this tour',
                text: "Please contact us for more details",
                icon: 'error',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
              });
            } else {
              ($("#staticBackdrop") as any).modal("show");
              this.allOrder.no_of_alltraveler = Array(+this.allOrder.no_of_traveler).fill(0).map((x, i) => (i + 1));
              this.totalPrice = this.basicPrice = +this.allOrder?.bookPrice;
              const allPackPriceData = JSON.parse(this.allOrder.packageType_price);
              this.getAllPackageData(allPackPriceData);
              this.getAllRoomShareData(JSON.parse(this.allOrder.roomSharing_price));
              this.getAllBoardingData(JSON.parse(this.allOrder.boarding_point_price));
              // this.calculateAllExtraChargesexclusive();
            }
          } else {
            if (this.allTourdate.DateArrCustomize.length > 0) {
              ($("#staticBackdrop") as any).modal("show");
              this.allOrder.no_of_alltraveler = Array(+this.allOrder.no_of_traveler).fill(0).map((x, i) => (i + 1));
              this.totalPrice = this.basicPrice = +this.allOrder?.bookPrice;
              const allPackPriceData = JSON.parse(this.allOrder.packageType_price);
              this.getAllPackageData(allPackPriceData);
              this.getAllRoomShareData(JSON.parse(this.allOrder.roomSharing_price));
              this.getAllBoardingData(JSON.parse(this.allOrder.boarding_point_price));
              // this.calculateAllExtraChargesexclusive();
            } else {
              Swal.fire({
                title: 'No schedule available for this tour',
                text: "Please contact us for more details",
                icon: 'error',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
              });
            }
          }
        }      
        this.isButtonDisabled = false;
      }, (error) => {
        this.isButtonDisabled = false;
      });
    }
  }
  

  onChange(selectedValue: any) {
    // console.log(selectedValue)
    //return
    this.allPackData = [];
    this.allRoomData = [];
    this.numberofchild = 0
    this.toltalchidprice = 0
    this.numberofinfant = 0
    this.toltalinfantprice = 0
    this.totalexclusivevalue = 0
    // Handle the logic based on the selected value
    // console.log('Selected value:', selectedValue);
    let dataForForm = {
      batch_id: selectedValue,
    };
    this.spinner.show();
    this.apiService.getbatchlistBy(dataForForm, this.tourID).subscribe((result: any) => {
      // console.log(result.
      //   select_batch
      //   );
      this.selectebatchPrice =  result.select_batch
      this.seleactegst = result.select_batch
      this.seleactetcs = result.select_batch
      // console.log(this.seleactegst.gst,  this.seleactetcs.tcs, 'hiiiiiiiiiiiiii');
      if (result.success) {
        // this.selectebatchNumber = result.
        // select_batch.child_price
        this.childprice = result.
        select_batch.child_price
        this.infantprice = result.
        select_batch.infant_price
        // console.log(this.childprice, this.infantprice)
        this.selectebatchPrice.no_of_alltraveler = Array(+this.selectebatchPrice.no_of_traveler).fill(0).map((x, i) => (i + 1));
          this.totalPrice = this.basicPrice = +this.selectebatchPrice?.bookPrice;
          const allPackPriceData = JSON.parse(this.selectebatchPrice.packageType_price);
          this.getAllPackageData(allPackPriceData);
          this.getAllRoomShareData(JSON.parse(this.selectebatchPrice.roomSharing_price));
          this.getAllBoardingData(JSON.parse(this.selectebatchPrice.boarding_point_price));
          this.spinner.hide();
          // this.calculateAllExtraChargesexclusive()
      }
    });
 }

 calculateExclusiveCharges() {
      // console.log('lll')
      if(this.seleactegst.gst == 'exclusive'){
        // console.log('ll')
        //return
        if (
          this.allOtherCharges?.transactional_charge &&
          this.allOtherCharges?.cgst &&
          this.allOtherCharges?.sgst &&
          this.allOtherCharges?.igst &&
          this.allOtherCharges?.tcs 
          ) {
          if (this.allData?.stateData?.iso2 === 'MP') {
            this.amtWithCGST = (this.allOtherCharges?.cgst * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.cgst * 0.01)+(this.allOtherCharges?.sgst * 0.01))))
            this.amtWithSGST = (this.allOtherCharges?.sgst * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.cgst * 0.01)+(this.allOtherCharges?.sgst * 0.01))))
            this.amtWithTCS  = (this.allOtherCharges?.tcs * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.tcs * 0.01))))
            this.totalexclusivevalue =  this.amtWithCGST +  this.amtWithSGST
            if(this.allTourdate?.domesticorInternational == 'international'){     
              this.totalexclusivevalue =   this.amtWithCGST + this.amtWithSGST + this.amtWithTCS
              // console.log(this.amtWithCGST,  this.totaltcscgstsgst)           
            }
            // console.log(this.finalPrice)
            this.totalAmountToPaid = this.finalPrice;
          } else {
            this.amtWithIGST = (this.allOtherCharges?.igst * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.igst * 0.01))))
            this.amtWithTCS  = (this.allOtherCharges?.tcs * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.tcs * 0.01))))
            this.totalAmountToPaid = this.finalPrice;
            this.totalexclusivevalue =  this.amtWithIGST
            // console.log( this.amtWithIGST,this.amtWithTCS,'llll')
            if(this.allTourdate?.domesticorInternational == 'international'){
              // console.log("International") 
              this.totalexclusivevalue = this.amtWithIGST + this.amtWithTCS
            }
          }
        }
      }
  }


  handleClose(){
    // this.allOrder = '';
    // this.selectedTour == '';
    // this.ifTourNotSelected = false;
    // this.onCategorySelect('');
    // console.log(this.allPackData, this.allRoomData);
    this.allPackData = [];
    this.allRoomData = [];
    this.numberofchild = 0
    this.toltalchidprice = 0
    this.numberofinfant = 0
    this.toltalinfantprice = 0
    this.startDate = '';
    this.endDate = '';
    this.packageValues = []
    this.roomValues = []
    this.numbercustomizedpackage = [];
    this.numbercustomizedroom = [];
    this.customizedpackageprice = 0;
    this.customizedroomprice = 0;
    this.totalexclusivevalue = 0;
    // this.sleeperPrice = 0;
    // this.ac3Price = 0;
    // this.ac2Price = 0;
    // this.ac1Price = 0;
    // this.flightPrice = 0;
    // this.singalboardingprice = 0;
    //this.calculatePrice(); 
    //this.totalTravellercustomized = 0;

  }

  editBoard(id) {
    let dataForForm = {
      id: id,
    };
    this.apiService.addWebSiteID(dataForForm).subscribe((result: any) => {
      //console.log(result.getData);
      this.getAllStates();
      this.allData = result.getData;
      // console.log(this.allData)
    });
  }

  reorderPackage(packArr: any[]) {
    packArr.sort((a,b) => (+a.packPrice > +b.packPrice) ? 1 : ((+b.packPrice > +a.packPrice) ? -1 : 0));
    this.allPackData = packArr.map((pack, i)=>{
      if (i===0) return {...pack, persons: 0}
      else return {...pack, persons: 0}
    });
    this.calculatePrice();
  }

  // addremovechild(obj: any, type: string){
  //   console.log(obj, type )
  //   if(type === 'add'){
  //     this.numberofchild++
  //    this.toltalchidprice =  obj*this.numberofchild
  //    console.log(this.toltalchidprice)
  //   }else if(type === 'minus' && this.numberofchild > 0){
  //     this.numberofchild--
  //     this.toltalchidprice = this.toltalchidprice - obj
  //     console.log(this.toltalchidprice)
  //   }
  //   this.calculatePrice()
  // }
  onPriceChangeChild( newValue: string) {
    const price = parseFloat(newValue);
    if (!isNaN(price) && price >= 0) {
      this.kidsValue = price;
    } else {
      this.kidsValue = 0;
      // console.log("Invalid or missing price value, setting to 0");
    }
    this.addremovechild(this.kidsValue, 'update');
  }

  addremovechild(obj: any, type: string) {
    // console.log(obj, type);

    // Validate if obj is a valid number greater than zero
    const validPrice = parseFloat(obj);
    if (isNaN(validPrice) || validPrice <= 0) {
        console.log("Invalid price value");
        return; // Exit the function if the price value is invalid
    }

    // Handle the action (add or minus)
    if (type === 'add') {
        this.numberofchild++;
        this.toltalchidprice = validPrice * this.numberofchild;
        // console.log(this.toltalchidprice);
    } else if (type === 'minus' && this.numberofchild > 0) {
        this.numberofchild--;
        this.toltalchidprice -= validPrice;
        // console.log(this.toltalchidprice);
    }
    else if (type === 'update') {
      // console.log(type,"jj");
      if(obj==0){
        this.toltalchidprice=-1
      }
      this.toltalchidprice = obj;
    }
    // Call the function to recalculate the total price
    this.calculatePrice();
}

  addremovechildcustomized(obj: any, type: string){
    // console.log(obj, type )
    // if(type === 'add'){
    //   this.numberofchild++
    //  this.toltalchidprice =  obj*this.numberofchild
    //  console.log(this.toltalchidprice)
    // }else if(type === 'minus' && this.numberofchild > 0){
    //   this.numberofchild--
    //   this.toltalchidprice = this.toltalchidprice - obj
    // }
    // this.calculatePrice()
  }

  // addremovecinfant(obj: any, type: string){
  //   console.log(obj, type )
  //   if(type === 'add'){
  //     this.numberofinfant++
  //    this.toltalinfantprice =  obj*this.numberofinfant
  //    console.log(this.toltalinfantprice)
  //   }else if(type === 'minus' && this.numberofinfant > 0){
  //     this.numberofinfant--
  //     this.toltalinfantprice = this.toltalinfantprice - obj
  //     console.log(this.toltalinfantprice)
  //   }
  //   this.calculatePrice()
  // }
  onPriceChangeInfant( newValue: string) {
    const price = parseFloat(newValue);
    if (!isNaN(price) && price >= 0) {
      this.infantprice = price;
    } else {
      this.infantprice = 0;
      console.log("Invalid or missing price value, setting to 0");
    }
    this.addremovecinfant(this.infantprice, 'update');
  }
  addremovecinfant(obj: any, type: string) {
    // console.log(obj, type);
    const validPrice = parseFloat(obj);
    if (isNaN(validPrice) || validPrice <= 0) {
        console.log("Invalid price value");
        return; 
    }
    if (type === 'add') {
        this.numberofinfant++;
        this.toltalinfantprice = validPrice * this.numberofinfant;
        console.log(this.toltalinfantprice);
    } else if (type === 'minus' && this.numberofinfant > 0) {
        this.numberofinfant--;
        this.toltalinfantprice -= validPrice;
        console.log(this.toltalinfantprice);
    }
   else if (type === 'update') {   
      this.toltalinfantprice =obj
  }
    this.calculatePrice();
}

onlyNumberKey(evt: KeyboardEvent) {
  let ASCIICode = (evt.which) ? evt.which : evt.keyCode;
  return (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) ? false : true;
}

onPriceChange(index: number, newValue: string) {
  const price = parseFloat(newValue);
  if (!isNaN(price) && price >= 0) {
    this.packageValues[index] = price;
  } else {
    this.packageValues[index] = 0;
    console.log("Invalid or missing price value, setting to 0");
  }
  this.addRemovePersonsPackage(index, this.allPackData[index], 'update');
}





addRemovePersonsPackage(index: number, packageData: any, action: string) {
  const packageType = this.allPackData[index].packType;
  const price = this.packageValues[index];
  const currentQuantity = this.numbercustomizedpackage[index];
  if (price < 0 || isNaN(price)) {
    console.log("Price value is not valid");
    return;
  }
  if (action === 'add') {
    this.numbercustomizedpackage[index]++;
  } else if (action === 'minus' && this.numbercustomizedpackage[index] > 0) {
    this.numbercustomizedpackage[index]--;
  } else if (action === 'update') {
    this.numbercustomizedpackage[index] = currentQuantity;
  }
  const setprice = price * this.numbercustomizedpackage[index];
  this.packagePrices[index] = setprice;
  this.customizedpackageprice = this.packagePrices.reduce((total, current) => total + (current || 0), 0);
  let dataObject = this.dataArray[index];
  if (!dataObject) {
    dataObject = {
      packType: packageType,
      packPrice: price,
      type: 'normal',
      persons: this.numbercustomizedpackage[index]
    };
  } else {
    dataObject.packType = packageType;
    dataObject.packPrice = price;
    dataObject.type = 'normal';
    dataObject.persons = this.numbercustomizedpackage[index];
  }
  this.dataArray[index] = dataObject;
  console.log("Data Array:", this.dataArray);
  this.calculatePrice();
}



  getTotalSelectedPackages(): number {
    let total = 0;
    for (let count of this.numbercustomizedpackage) {
        total += count;
    }
    this.customizedpackagetotaluser = total
    //this.calculatePrice()
    //console.log(this.customizedpackagetotaluser)
    return total;
  }

  // addRemovePersonsRoom(index: number, roomData: any, action: string) { 
  //   console.log(roomData, action, index, this.numbercustomizedroom);
  //   //return
  //   const roomType = this.allRoomData[index].roomType;
  //   const price = this.allRoomData[index].price;
  //   const value = this.roomValues[index];
  //   const currentQuantity = this.numbercustomizedroom[index];
  //   console.log("Current Quantity:", currentQuantity);
  //   console.log(roomType, value)
  //   if(action === 'add' && this.customizedroomuser < this.customizedpackagetotaluser ){
  //     //  this.toast.fire({
  //     //   icon: 'error',
  //     //   title: 'Add travellers to add rooms',
  //     // });
  //     this.numbercustomizedroom[index]++;
  //     console.log("Increased Quantity:", this.numbercustomizedroom[index]);
  //     this.customizedroomprice = value * this.numbercustomizedroom[index];
  //     //console.log(this.customizedpackageprice)
  //   }else if(action === 'minus' && this.numbercustomizedroom[index] > 0){
  //     this.numbercustomizedroom[index]--;
  //     console.log("Decreased Quantity:", this.numbercustomizedroom[index]);
  //     this.customizedroomprice = value * this.numbercustomizedroom[index];
  //   }   
  //   this.getTotalSelectedRoom()
  //   const setprice = this.roomValues[index] * this.numbercustomizedroom[index];
  //   this.roomPrices[index] = setprice;
  //   this.customizedroomprice = this.roomPrices.reduce((total, current) => total + (current || 0), 0);
  //   let dataObject = this.dataArrayroom[index];
  //   if (!dataObject) {
  //       // If not, create a new object
  //       dataObject = {
  //         roomType: roomType,
  //           value: value,
  //           persons: this.numbercustomizedroom[index]
  //       };
  //   } else {
  //       // If it exists, update its properties
  //       dataObject.roomType = roomType;
  //       dataObject.roomPrice = value;
  //       dataObject.persons = this.numbercustomizedroom[index];
  //   }
  //   // Update the data in dataArray
  //   this.dataArrayroom[index] = dataObject;
  //   //dataArray[index] = dataObject;
  //   console.log("Data Array:", this.dataArrayroom, this.singalboardingprice);  
  //   this.calculatePrice(); 
  //   //Perform operations based on packageType, price, and value
  // }
  onPriceChangeRoomSharing(index: number, newValue: string) {
    const price = parseFloat(newValue);
    if (!isNaN(price) && price >= 0) {
      this.roomPrices[index] = price;
    } else {
      this.roomPrices[index] = 0;
      console.log("Invalid or missing price value, setting to 0");
    }
    this.addRemovePersonsRoom(index, this.roomPrices[index], 'update');
  }
  addRemovePersonsRoom(index: number, roomData: any, action: string) {
    console.log(roomData, action, index, this.numbercustomizedroom);

    // Retrieve the relevant data
    const roomType = this.allRoomData[index].roomType;
    const price = this.allRoomData[index].price;
    const value = this.roomValues[index]; 
    const currentQuantity = this.numbercustomizedroom[index];

    console.log("Current Quantity:", currentQuantity);
    console.log(roomType, value);
    const validPrice = parseFloat(value as unknown as string);
    if (isNaN(validPrice) || validPrice <= 0) {
      this.customizedroomprice =0
        console.log("Invalid price value");
        return; 
    }
    if (action === 'add' && this.customizedroomuser < this.customizedpackagetotaluser) {
        this.numbercustomizedroom[index]++;
        console.log("Increased Quantity:", this.numbercustomizedroom[index]);
        this.customizedroomprice = validPrice * this.numbercustomizedroom[index];
    }
     else if (action === 'minus' && this.numbercustomizedroom[index] > 0) {
        this.numbercustomizedroom[index]--;
        console.log("Decreased Quantity:", this.numbercustomizedroom[index]);
        this.customizedroomprice = validPrice * this.numbercustomizedroom[index];
    }
    else if (action === 'update') {
     
      this.customizedroomprice = roomData
  }

    // Update total room prices
    this.getTotalSelectedRoom();
    const setprice = validPrice * this.numbercustomizedroom[index];
    this.roomPrices[index] = setprice;
    this.customizedroomprice = this.roomPrices.reduce((total, current) => total + (current || 0), 0);

    // Update dataArrayroom
    let dataObject = this.dataArrayroom[index];
    if (!dataObject) {
        // If not, create a new object
        dataObject = {
            roomType: roomType,
            roomPrice: validPrice,
            persons: this.numbercustomizedroom[index]
        };
    } else {
        // If it exists, update its properties
        dataObject.roomType = roomType;
        dataObject.roomPrice = validPrice;
        dataObject.persons = this.numbercustomizedroom[index];
    }
    // Update the data in dataArrayroom
    this.dataArrayroom[index] = dataObject;

    console.log("Data Array:", this.dataArrayroom, this.singalboardingprice);

    // Call the function to recalculate the total price
    this.calculatePrice();
}

// addRemovePersonsRoom(index: number, roomData: any, action: string) {
//   console.log(roomData, action, index, this.numbercustomizedroom);
//   const roomType = this.allRoomData[index]?.roomType;
//   const value = this.roomValues[index];
//   const currentQuantity = this.numbercustomizedroom[index];
//   console.log("Current Quantity:", currentQuantity);
//   console.log(roomType, value);
//   const validPrice = parseFloat(value as unknown as string);
//   if (isNaN(validPrice) || validPrice <= 0) {
//       this.customizedroomprice = 0;
//       console.log("Invalid price value");
//       return;
//   }
//   if (action === 'add') {
//       if (this.customizedroomuser < this.customizedpackagetotaluser) {
//           this.numbercustomizedroom[index]++;
//           console.log("Increased Quantity:", this.numbercustomizedroom[index]);
//           this.customizedroomprice = validPrice * this.numbercustomizedroom[index];
//       } else {
//           Swal.fire({
//               icon: 'error',
//               title: 'Oops...',
//               text: 'Cannot add more items. Maximum limit reached.',
//           });
//       }
//   } 
//   else if (action === 'minus') {
//       if (this.numbercustomizedroom[index] > 0) {
//           this.numbercustomizedroom[index]--;
//           console.log("Decreased Quantity:", this.numbercustomizedroom[index]);
//           this.customizedroomprice = validPrice * this.numbercustomizedroom[index];
//       }
//   } 
//   else if (action === 'update') {
//       this.customizedroomprice = roomData;
//   }
//   this.getTotalSelectedRoom();
//   const setprice = validPrice * this.numbercustomizedroom[index];
//   this.roomPrices[index] = setprice;
//   this.customizedroomprice = this.roomPrices.reduce((total, current) => total + (current || 0), 0);
//   let dataObject = this.dataArrayroom[index];
//   if (!dataObject) {
//       dataObject = {
//           roomType: roomType,
//           roomPrice: validPrice,
//           persons: this.numbercustomizedroom[index]
//       };
//   } else {
//       dataObject.roomType = roomType;
//       dataObject.roomPrice = validPrice;
//       dataObject.persons = this.numbercustomizedroom[index];
//   }
//   this.dataArrayroom[index] = dataObject;
//   console.log("Data Array:", this.dataArrayroom, this.singalboardingprice);
//   this.calculatePrice();
// }


  updatePriceEntered(boardingPoint: string, price: number): void {
    // Update price entry status for the corresponding boarding point
    switch (boardingPoint) {
      case 'singal':
        this.singalboardingprice = price;
        this.boardingPointPricesEntered.singal = !!this.singalboardingprice;
        break;
      case 'sleeper':
        this.sleeperPrice = price;
        this.boardingPointPricesEntered.sleeper = !!this.sleeperPrice;
        //this.boardingPointPrices.sleeper = price; // Store price for sleeper
        break;
      case 'ac3':
        this.ac3Price = price;
        this.boardingPointPricesEntered.ac3 = !!this.ac3Price;
        //this.boardingPointPrices.ac3 = price; // Store price for ac3
        break;
      case 'ac2':
        this.ac2Price = price;
        this.boardingPointPricesEntered.ac2 = !!this.ac2Price;
        //this.boardingPointPrices.ac2 = price; // Store price for ac2
        break;
      case 'ac1':
        this.ac1Price = price;
        this.boardingPointPricesEntered.ac1 = !!this.ac1Price;
        //this.boardingPointPrices.ac1 = price; // Store price for ac1
        break;
      case 'flight':
        this.flightPrice = price;
        this.boardingPointPricesEntered.flight = !!this.flightPrice;
        //this.boardingPointPrices.flight = price; // Store price for flight
        break;
      default:
        break;
    }

    // Check if any price has been entered among all boarding points
    this.priceEntered = Object.values(this.boardingPointPricesEntered).some(value => value);
    console.log(`Price entered for ${boardingPoint}:`, price);
    this.boardingPointPrices = price
    this.boardingPointType = {boardingPoint}
    // console.log('Any price entered?', this.priceEntered);
    console.log('Boarding point prices:', this.boardingPointPrices, this.boardingPointType.boardingPoint
    );
    //this.enteredPrices.push({ boardingPoint: boardingPoint, price: price });
    //console.log('Any price entered?', this.priceEntered);
    //console.log('Entered prices:', this.enteredPrices);
   this.calculatePrice()
}


  getTotalSelectedRoom(): number {
    let totalroom = 0;
    for (let count of this.numbercustomizedroom) {
      totalroom += count;
    }
    this.customizedroomuser = totalroom 
    //console.log(this.customizedroomuser)
    //this.calculatePrice()
    return totalroom;
  }



  addRemovePersons(obj: any, type: string) {
    // console.log(obj, type )
    // return
    if ("roomType" in obj && this.totalTraveller <= this.allRoomData.reduce((total, data) => total + data.persons, 0)) {
      this.toast.fire({
        icon: 'error',
        title: 'Add travellers to add rooms',
      });
      // return;
    }
    if ("packType" in obj) {
      this.allRoomData.map(room => room.persons = 0);
    }
    if (type === 'add' && this.totalTraveller > this.allRoomData.reduce((total, data) => total + data.persons, 0)) obj.persons++
    else if ("packType" in obj && type === 'add' && this.totalTraveller == 0 && this.allRoomData.reduce((total, data) => total + data.persons, 0) == 0) obj.persons++
    else if (type === 'minus' && obj.persons > 0) obj.persons--
    this.calculatePrice();
  }

  getSingleBoardPrice(value: string) {
    const boardPoint = this.allOrder.boarding_point_price.filter(
      (arrEle) => arrEle.bp === value
    );
    if (boardPoint[0].length !== 0 && boardPoint[0].bp_type === 'single')
     return `(₹${boardPoint[0].bp_price[0]?.singleBoardPrice}) Per Person`;
    else return '';
  }


  calculatePrice() {
    this.finalPrice = 0;
    const totalPriceByPackage = this.allPackData.reduce((total, data) => {
      return total + data.packPrice*data.persons
    }, 0);
    //console.log(totalPriceByPackage)
    const totalPriceByRoom = this.allRoomData.reduce((total, data) => {
      return total + data.roomPrice*data.persons
    }, 0);
    this.totalTraveller = this.allPackData.reduce((total, data) => {
      return total + data.persons
    }, 0);
    this.finaltotalTraveller = this.numberofchild + this.totalTraveller +  this.numberofinfant
    this.totalTravellercustomized = this.getTotalSelectedPackages() +  this.numberofchild + this.numberofinfant
    // console.log(this.totalTravellercustomized,'ll')
    if(this.tourtype == 'customized'){
      //this.finalPrice = 0;
      this.finalPrice =  this.customizedpackageprice + this.customizedroomprice + this.toltalchidprice + this.toltalinfantprice + this.totalTravellercustomized*this.boardingPointPrices;
      // console.log( this.finalPrice,this.customizedpackageprice + this.customizedroomprice + this.toltalchidprice + this.toltalinfantprice + this.totalTravellercustomized*this.boardingPointPrices)
    }else{
      this.finalPrice = totalPriceByPackage + totalPriceByRoom + this.toltalchidprice + this.toltalinfantprice + this.finaltotalTraveller*this.boardPrice;
    }
   
  }

  onOptionSelect(value: any, type: string) {
    // console.log(value,type)
   
    this.boarding_point =  value

    
    this.totalPrice = this.basicPrice;
    value =
      type !== 'traveller'
        ? value === ''
          ? '0'
          : value
        : value === ''
        ? '1'
        : value;
    //console.log(value);
    if (type === 'pack') {
      if (!isNaN(value)) {
        this.packPrice = +value;
        this.packBikePrice = 0;
        this.packTypeSelected = '';
      } else {
        this.packPrice = 0;
        this.packBikePrice = 0;
        this.packTypeSelected = value;
      }
    }
    if (this.packTypeSelected === "solo-bike" && type === "solo") {
      this.packBikePrice = +value;
    } else if (this.packTypeSelected === "share-bike" && type === "share") {
      this.packBikePrice = +value;
    }
    if (type === 'room') {
      this.roomPrice = +value;
    }
    if (type === 'traveller') {
      this.no_of_traveller = +value;
    }
    if (type === 'board') {
      if (value === '0') {
        this.boardPrice = 0;
        this.multipleBoarded = [];
      } else {
        this.multipleBoarded = this.allOrder.boarding_point_price.filter(
          (arrEle) => arrEle.bp === value
        );
        if (
          this.multipleBoarded.length !== 0 &&
          this.multipleBoarded[0]?.bp_type === 'single'
        ) {
          this.boardPrice =
            +this.multipleBoarded[0]?.bp_price[0]?.singleBoardPrice;
        } else {

          //console.log(this.multipleBoarded, this.multipleBoarded[0]);
          if (this.multipleBoarded[0]?.bp_price[4]?.Flight) {
            this.boardPrice = +this.multipleBoarded[0]?.bp_price[4]?.Flight;
          }
          if ((this.multipleBoarded[0]?.bp_price[3])['1AC']) {
            this.boardPrice = +(this.multipleBoarded[0]?.bp_price[3])['1AC'];
          }
          if ((this.multipleBoarded[0]?.bp_price[2])['2AC']) {
            this.boardPrice = +(this.multipleBoarded[0]?.bp_price[2])['2AC'];
          }
          if ((this.multipleBoarded[0]?.bp_price[1])['3AC']) {
            this.boardPrice = +(this.multipleBoarded[0]?.bp_price[1])['3AC'];
          }
          if (this.multipleBoarded[0]?.bp_price[0]?.Sleeper) {
            this.boardPrice = +this.multipleBoarded[0]?.bp_price[0]?.Sleeper;
          }
          //console.log(this.boardPrice);
        }
      }
    }
    if (type === 'boardMulti') {
      this.boardPrice = +value;
    }
   // console.log(this.packPrice, this.packBikePrice, this.roomPrice, this.boardPrice);
    this.totalPrice += this.packPrice + this.packBikePrice + this.roomPrice + this.boardPrice;
    this.totalPrice *= this.no_of_traveller;
    this.calculatePrice();
  }


  setChange(){
    // this.calculateAllExtraChargesexclusive()
    ///return;
    const dateSchedule = this.dateSchedule?.nativeElement?.value;
    // console.log(dateSchedule)
    // //return
    const boardingPt =
      this.boardingPt?.nativeElement?.options[
        this.boardingPt?.nativeElement?.selectedIndex
      ].dataset.getboardid;
    const boardingMulti =
      this.boardingMulti?.nativeElement?.options[
        this.boardingMulti?.nativeElement?.selectedIndex
      ].text;
    const packageDataWithTraveller = this.allPackData.filter(val => val.persons !== 0);
    const numberOfTraveller = this.totalTraveller;
    if (dateSchedule === '') {
      Swal.fire({
        text: 'Please select your tour date',
        icon: 'error',
      });
      // this.allPackData = [];
      // this.allRoomData = [];
      return;
    }
    if (packageDataWithTraveller.length === 0) {
      Swal.fire({
        text: 'Please add travellers to the package',
        icon: 'error',
      });
      // this.allPackData = [];
      // this.allRoomData = [];
      return;
    }
    if (boardingPt === undefined) {
      Swal.fire({
        text: 'Please select your boarding point',
        icon: 'warning',
      });
      // this.allPackData = [];
      // this.allRoomData = [];
      return;
    }
    if (numberOfTraveller === 0) {
      Swal.fire({
        text: 'Please add travellers',
        icon: 'error',
      });
      // this.allPackData = [];
      // this.allRoomData = [];
      return;
    }

    // this.spinner.show();
    const orderDetails = {
      user_id: this.webID,
      tour_id:  this.tourID,
      travel_date: dateSchedule,
      tourDate:dateSchedule,
      travel_packageType_data: this.allPackData,
      travel_roomSharing_data: this.allRoomData,
      travel_boarding_point: boardingPt,
      boardingType: boardingMulti ? boardingMulti.split(" ")[0] : '',
      travel_no_of_traveler: this.finaltotalTraveller,
      old_travel_no_of_traveler: this.finaltotalTraveller,
      infant_price: this.toltalinfantprice,
      child_price: this.toltalchidprice,
      finalPrice: this.finalPrice + this.totalexclusivevalue,
      child_count: this.numberofchild,
      infant_count: this.numberofinfant,
      tour_type: this.tourtype,
      bordingprice:this.boardPrice,
      domesticorinternational: this.allTourdate?.domesticorInternational,
      status: 'active',
      batch_number: this.selectebatchNumber
    }
    // console.log(orderDetails);
    //return;
    // let notificationData = {
    //   applyTour_id: this.allOrder._id,
    //   last_amount_paid : this.isPartialPay
    //   ? +this.allTourData?.bookPrice *
    //     +this.allCheckoutTourData?.travel_no_of_traveler
    //   : this.totalAmountToPaid,
    //   is_new: true,
    //   status: "",
    //   payment_status: ""
    // }
    //this.spinner.show();
      this.apiService.AdminTour(orderDetails).subscribe((res: any) => {
        if (res.success) {
          ($("#staticBackdrop")as any).modal("hide");

          this.spinner.hide();
          this.FinalBooking = true;
          this.getCheckoutTourDetails(res.objId);

       
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          // notificationData = {...notificationData, payment_status: "Paid"}
          //         console.log(notificationData, "NOTIFICATION!!!");

          // this.apiService.postNotification(notificationData).subscribe((res: any) => {
          //   if(res){
          //     console.log(res);
          //   }
          // })
        } else {
          this.spinner.hide();
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
        }
      });
  }

  
  setChangecustomized(){
    // this.calculateAllExtraChargesexclusive();
    const dateSchedule = this.startDate
    const enddateSchedule = this.endDate
    // console.log(enddateSchedule)
    const packageDataWithTraveller = this.dataArray.filter(item => item.packPrice !== undefined && item.persons !== 0);
    const roomDataWithTraveller = this.dataArrayroom.filter(item => item.roomPrice !== undefined && item.persons !== 0);
    const boardingPt =
    this.boardingPt?.nativeElement?.options[
      this.boardingPt?.nativeElement?.selectedIndex
    ].dataset.getboardid;
    // console.log(boardingPt ,">>>");
    
  const boardingMulti =
    this.boardingMulti?.nativeElement?.options[
      this.boardingMulti?.nativeElement?.selectedIndex
    ].text;
  // console.log(boardingPt) 
   const numberOfTraveller = this.customizedpackagetotaluser + this.numberofchild + this.numberofinfant
    // console.log(numberOfTraveller)
    if (dateSchedule === '') {
      Swal.fire({
        text: 'Please select your tour start date', 
        icon: 'error',
      });
      return;
    }
    if (enddateSchedule === '') {
      Swal.fire({
        text: 'Please select your tour end date', 
        icon: 'error',
      });
      return;
    }
    if (packageDataWithTraveller.length === 0) {
      Swal.fire({
        text: 'Please add travellers to the package',
        icon: 'error',
      });
      return;
    }
    // if (roomDataWithTraveller.length === 0) {
    //   Swal.fire({
    //     text: 'Please add travellers to the room',
    //     icon: 'error',
    //   });
    //   return;
    // }
    if (boardingPt === undefined) {
      Swal.fire({
        text: 'Please select your boarding point',
        icon: 'warning',
      });
      return;
    }
    if (numberOfTraveller === 0) {
      Swal.fire({
        text: 'Please add travellers',
        icon: 'error',
      });
      return;
    }
    // console.log('hello')
    //return
    const orderDetails = {
      user_id: this.webID,
      tour_id:  this.tourID,
      travel_date: dateSchedule,
      tourDate:enddateSchedule,
      travel_packageType_data: this.dataArray,
      travel_roomSharing_data: this.dataArrayroom,
      boardingpointustomized: this.boarding_point,
      boardingTypepricecustomized:  this.boardingPointPrices,
      //travel_boarding_point: this.boardingPointPrices,
      boardingType: this.boardingPointType.boardingPoint,
      travel_no_of_traveler: this.totalTravellercustomized,
      old_travel_no_of_traveler: this.totalTravellercustomized,
      infant_price: this.toltalinfantprice,
      child_price: this.toltalchidprice,
      finalPrice: this.finalPrice,
      child_count: this.numberofchild,
      infant_count: this.numberofinfant,
      tour_type: this.tourtype,
      batchID:  this.selectebatchID,
      bookPrice: this.bookingpricecust,
      gst: this.gst,
      tcs: this.tcs,
      status: 'active',
      domesticorinternational: this.allTourdate?.domesticorInternational
    };
    // console.log(orderDetails);
    //return;
    this.spinner.show();
      this.apiService.AdminTourcustomized(orderDetails).subscribe((res: any) => {
        if (res.success) {
          ($("#staticBackdrop")as any).modal("hide");
          this.spinner.hide();
          this.FinalBookingcustomized = true;
          // console.log(this.FinalBookingcustomized)
          this.getCheckoutTourDetails(res.objId);
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
        } else {
          this.spinner.hide();
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
        }
      });
  }

  // onlyNumberKey(evt: KeyboardEvent) {
  //   // Only ASCII character in that range allowed
  //   let ASCIICode = (evt.which) ? evt.which : evt.keyCode;
  //   return (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) ? false : true;
  // }



   ////////////////////////oldcodeforconfirmtour//////////////////////////////////////
  // setChange() {
  //   const dateSchedule = this.dateSchedule?.nativeElement?.value;
  //   let packageSoloType;
  //   let packageShareType;
  //   const packageType = this.package?.nativeElement?.options[
  //     this.package?.nativeElement?.selectedIndex
  //   ].dataset.getpackid;
  //   if (this.packTypeSelected === "solo-bike") {
  //     packageSoloType =
  //     this.solo?.nativeElement?.options[
  //       this.solo?.nativeElement?.selectedIndex
  //     ].dataset.getsoloid;
  //   } else if (this.packTypeSelected === "share-bike") {
  //     packageShareType =
  //     this.share?.nativeElement?.options[
  //       this.share?.nativeElement?.selectedIndex
  //     ].dataset.getsharedid;
  //   }
  //   const roomName = this.roomShare?.nativeElement?.options[
  //       this.roomShare?.nativeElement?.selectedIndex
  //   ].dataset.getroomid;
  //   const boardingPt = this.boardingPt?.nativeElement?.options[
  //     this.boardingPt?.nativeElement?.selectedIndex
  //   ].dataset.getboardid;
  //   const boardingMulti = this.boardingMulti?.nativeElement?.options[
  //     this.boardingMulti?.nativeElement?.selectedIndex
  //   ].text;
  //   const numberOfTraveller = this.numberOfTraveller?.nativeElement?.value;
  //   if (dateSchedule === '') {
  //     Swal.fire({
  //       text: 'Please select your tour date',
  //       icon: 'warning',
  //     });
  //     return false;
  //   }
  //   if (this.allOrder.packageType_price.length !== 0) {
  //     if (packageType === undefined) {
  //       Swal.fire({
  //         text: 'Please select your package type',
  //         icon: 'warning',
  //       });
  //       return false;
  //     }
  //   }
  //   if (this.packTypeSelected === "solo-bike") {
  //     if (packageSoloType === undefined) {
  //       Swal.fire({
  //         text: 'Please select your solo bike type',
  //         icon: 'warning'
  //       });
  //       return;
  //     }
  //   } else if (this.packTypeSelected === "share-bike") {
  //     if (packageShareType === undefined) {
  //       Swal.fire({
  //         text: 'Please select your shared bike type',
  //         icon: 'warning'
  //       });
  //       return;
  //     }
  //   }
  //   if (this.allOrder.roomSharing_price.length !== 0) {
  //     if (roomName === undefined) {
  //       Swal.fire({
  //         text: 'Please select your room name',
  //         icon: 'warning',
  //       });
  //       return;
  //     }
  //   }
  //   if (boardingPt === undefined) {
  //     Swal.fire({
  //       text: 'Please select your boarding point',
  //       icon: 'warning',
  //     });
  //     return false;
  //   }
  //   if (numberOfTraveller === '') {
  //     Swal.fire({
  //       text: 'Please select number of travellers',
  //       icon: 'warning',
  //     });
  //     return false;
  //   }
  //   const orderDetails = {
  //     user_id: this.webID,
  //     tour_id: this.allOrder._id,
  //     travel_date: dateSchedule,
  //     tourDate: `${(dateSchedule.split('-')[0])?.split('/')[1]}/${(dateSchedule.split('-')[0])?.split('/')[0]}/${(dateSchedule.split('-')[0])?.split('/')[2]}`,
  //     travel_packageType: packageType ? packageType : '',
  //     travel_soloPackageType: packageSoloType ? packageSoloType : '',
  //     travel_sharedPackageType: packageShareType ? packageShareType : '',
  //     travel_roomSharing: roomName ? roomName : '',
  //     travel_packageType_data: this.allPackData,
  //     travel_roomSharing_data: this.allRoomData,
  //     travel_boarding_point: boardingPt,
  //     boardingType: boardingMulti ? boardingMulti.split(" ")[0] : '',
  //     travel_no_of_traveler: numberOfTraveller,
  //     finalPrice: this.booking,
  //     status: 'active',
  //   };
  //   this.spinner.show();
  //   this.apiService.AdminTour(orderDetails).subscribe((res: any) => {
  //     if (res.success) {
  //       this.spinner.hide();
  //       this.FinalBooking = true;
  //       this.getCheckoutTourDetails(res.objId);
  //       Swal.fire({
  //         text: res.message,
  //         icon: 'success',
  //       });
  //     } else {
  //       this.spinner.hide();
  //       Swal.fire({
  //         text: res.message,
  //         icon: 'error',
  //       });
  //     }
  //   });
  // }

  // onCoupanSelect(couponcode) {
  //   const couponDetail = {
  //     user_id: this.webID,
  //     coupon: this.appliedCoupon,
  //     tour_price: this.allCheckoutTourData?.pricetourWithoutTaxes,
  //     tour_id: this.allCheckoutTourData?.tour_id?._id,
  //     id:this.allCheckoutTourData?._id
      
  //   };
 
  //   // Resetting state before applying coupon
  //   this.discountedPriceByCoupon = 0;
  //   this.isCouponNotApplied = false;
  //   this.isCouponApplied = false;
  //   this.finalPrice = +this.allCheckoutTourData?.finalPrice;
  //   this.FinalTourPrice = +this.allCheckoutTourData?.pricetourWithoutTaxes;


 
  //   console.log(this.FinalTourPrice,">>>>>>");
   
 
  //   console.log("Initial final price:", this.finalPrice);
  //   console.log("Coupon detail:", couponDetail);
 
  //   this.apiService.Coupan(couponDetail).subscribe((res: any) => {
  //     if (res.success) {
 
  //       this.couponRes = res;

  //       this.FinalTourPrice=res.getData.pricetourWithoutTaxes;

  //          this.amtWithCGSTNew =res.getData.amtWithCGSTNewCharges 
  //          this.amtWithIGSTNew =res.getData.amtWithIGSTNewCharges 
  //          this.amtWithTCSNew =res.getData.amtWithTCSNewCharges 
  //          this.amtWithSGSTNew =res.getData.amtWithSGSTNewCharges
  //          this.totalAmountToPaid =res.getData.finalPrice

  //          console.log(   this.subtotal,">>>>>>>>");
           
 
  //       // if (res?.discount_type === 'Fixed Amount') {
  //       //   this.discountedPriceByCoupon = res?.coupon_type === "Voucher"
  //       //     ? res?.discountAmount
  //       //     : res?.discountAmount * (+this.allCheckoutTourData?.travel_no_of_traveler);
  //       // } else if (res?.discount_type === 'Percentage Discount') {
  //       //   this.discountedPriceByCoupon = res?.discountAmount;
 
  //       //   console.log(this.discountedPriceByCoupon);
         
  //       // }
 
  //       console.log(this.discountedPriceByCoupon);
 
  //       // this.finalPrice -= this.discountedPriceByCoupon;
  //       // this.FinalTourPrice -= this.discountedPriceByCoupon;  
  //       // console.log("Updated final price:", this.finalPrice);
  //       // console.log("Updated final price:", this.FinalTourPrice);
       
  //       // this.calculateAllExtraChargesexclusive();
  //       this.isCouponApplied = true;
  //       Swal.fire({
  //         text: res.message,
  //         icon: 'success',
  //       });
        
  //     } else {
  //       Swal.fire({
  //         text: res.message,
  //         icon: 'error',
  //       });
 
  //       this.isCouponNotApplied = true;
  //       console.error(res);
  //     }
  //   });
  // }


  isDropdownDisabled: boolean = false; // To track dropdown enabled/disabled state
  // isCouponApplied: boolean = false; // To track if a coupon is applied
  
  onCoupanSelect(couponcode: string) {
    let couponDetail: any = {};
    if (couponcode) {
      couponDetail = {
        user_id: this.webID,
        coupon: couponcode,
        tour_price: this.allCheckoutTourData?.pricetourWithoutTaxes,
        tour_id: this.allCheckoutTourData?.tour_id?._id,
        id: this.allCheckoutTourData?._id
      };
      this.appliedCoupon = couponcode;
  
      // Reset states before the API call
      this.isCouponNotApplied = false;
  
      this.apiService.Coupan(couponDetail).subscribe((res: any) => {
        if (res.success) {
          // If coupon is successfully applied
          this.couponRes = res;
          this.FinalTourPrice = res.getData.pricetourWithoutTaxes;
          this.amtWithCGSTNew = res.getData.amtWithCGSTNewCharges;
          this.amtWithIGSTNew = res.getData.amtWithIGSTNewCharges;
          this.amtWithTCSNew = res.getData.amtWithTCSNewCharges;
          this.amtWithSGSTNew = res.getData.amtWithSGSTNewCharges;
          this.totalAmountToPaid = res.getData.finalPrice;
          this.discountedPriceByCoupon = res.getData.appliedCoupon * res.getData.travel_no_of_traveler;
  
          this.isCouponApplied = true; // Mark coupon as applied
          this.isDropdownDisabled = true; // Disable dropdown after successful coupon application
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
        } else {
          // If coupon application fails
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
          this.isCouponNotApplied = true; // Mark coupon as not applied
          this.appliedCoupon = ''; // Clear applied coupon
          this.isDropdownDisabled = false; // Keep dropdown enabled for further selection
        }
      });
    }
  }
  
  removeCoupon() {
    // Logic to remove coupon
    let couponDetail = {
      user_id: this.webID,
      coupon: this.appliedCoupon,
      tour_price: this.allCheckoutTourData?.pricetourWithoutTaxes,
      tour_id: this.allCheckoutTourData?.tour_id?._id,
      id: this.allCheckoutTourData?._id,
      removeCoupon: 'Remove'
    };
  
    this.apiService.Coupan(couponDetail).subscribe((res: any) => {
      if (res.success) {

        this.couponRes = res;
              this.FinalTourPrice = res.getData.pricetourWithoutTaxes;
              this.amtWithCGSTNew = res.getData.amtWithCGSTNewCharges;
              this.amtWithIGSTNew = res.getData.amtWithIGSTNewCharges;
              this.amtWithTCSNew = res.getData.amtWithTCSNewCharges;
              this.amtWithSGSTNew = res.getData.amtWithSGSTNewCharges;
              this.totalAmountToPaid = res.getData.finalPrice;
            this.discountedPriceByCoupon = res.getData.appliedCoupon * res.getData.travel_no_of_traveler
        Swal.fire({
          text: res.message,
          icon: 'success',
        });
        this.appliedCoupon = ''; // Clear applied coupon
        this.isDropdownDisabled = false; // Re-enable dropdown
        this.isCouponApplied = false; // Mark coupon as removed
      } else {
        Swal.fire({
          text: res.message,
          icon: 'error',
        });
      }
    });
  }
  // onCoupanSelect(couponcode: string) {
  //   let couponDetail: any = {};
  //   if (couponcode === 'remove') {
  //     couponDetail = {
  //       user_id: this.webID,
  //       coupon:  this.removecoupan,
  //       tour_price: this.allCheckoutTourData?.pricetourWithoutTaxes,
  //       tour_id: this.allCheckoutTourData?.tour_id?._id,
  //       id: this.allCheckoutTourData?._id,
  //       removeCoupon: 'Remove' 
  //     };
  //     this.appliedCoupon = '';
  //   } else {
  //     couponDetail = {
  //       user_id: this.webID,
  //       coupon: couponcode, 
  //       tour_price: this.allCheckoutTourData?.pricetourWithoutTaxes,
  //       tour_id: this.allCheckoutTourData?.tour_id?._id,
  //       id: this.allCheckoutTourData?._id
  //     };
  //     this.appliedCoupon = couponcode;
  //   }
  //   this.discountedPriceByCoupon = 0;
  //   this.isCouponNotApplied = false;
  //   this.isCouponApplied = false;
  //   this.finalPrice = +this.allCheckoutTourData?.finalPrice;
  //   this.FinalTourPrice = +this.allCheckoutTourData?.pricetourWithoutTaxes;
  
  //   console.log(this.FinalTourPrice, ">>>>>>>");
  //   console.log("Initial final price:", this.finalPrice);
  //   console.log("Coupon detail:", couponDetail);
  //   this.removecoupan = couponDetail.coupon
  //   this.apiService.Coupan(couponDetail).subscribe((res: any) => {
  //     if (res.success) {
  //       this.couponRes = res;
  //       this.FinalTourPrice = res.getData.pricetourWithoutTaxes;
  //       this.amtWithCGSTNew = res.getData.amtWithCGSTNewCharges;
  //       this.amtWithIGSTNew = res.getData.amtWithIGSTNewCharges;
  //       this.amtWithTCSNew = res.getData.amtWithTCSNewCharges;
  //       this.amtWithSGSTNew = res.getData.amtWithSGSTNewCharges;
  //       this.totalAmountToPaid = res.getData.finalPrice;
  //     this.discountedPriceByCoupon = res.getData.appliedCoupon * res.getData.travel_no_of_traveler
  
  //       console.log(this.subtotal, ">>>>>>>");
  //       console.log(this.discountedPriceByCoupon);
  //       this.isCouponApplied = true;
  //       Swal.fire({
  //         text: res.message,
  //         icon: 'success',
  //       });
  //     } else {
  //       Swal.fire({
  //         text: res.message,
  //         icon: 'error',
  //       });
  //       this.isCouponNotApplied = true;
  //       console.error(res);
  //     }
  //   });
  // }
  
 


  discardCoupon() {
    this.isCouponNotApplied = false;
    this.isCouponApplied = false;
    this.appliedCoupon = '';
    // this.discountedPriceByCoupon = 0;
    this.finalPrice = +this.allCheckoutTourData?.finalPrice;
    this.FinalTourPrice = +this.allCheckoutTourData?.pricetourWithoutTaxes;

    console.log(this.FinalTourPrice,">>>>>>>>");
    
    // this.calculateAllExtraChargesexclusive();
  }

  booking(type: String) {
    if (this.payment_status === '') {
      Swal.fire({
        text: 'Please select payment status',
        icon: 'warning',
      });
      return;
    }
    if(this.allCheckoutTourData.domesticorInternational == 'international'){
      if (this.pan_card === ''  ) {
        Swal.fire({
          text: 'Please enter pan card number',
          icon: 'warning',
        });
        return;
      }
        const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
        if (!panRegex.test(this.pan_card)) {
          Swal.fire({
            text: 'Please enter a valid PAN card number',
            icon: 'warning',
          });
          return;
        }
    }
    if (this.cusGSTNo !== '' && this.cusGSTNo.length !== 15) {
      Swal.fire({
        title: 'Error!',
        width: 400,
        text: 'Please enter valid GST number',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }
    //console.log(this.cusGSTNo);
    if (this.cusGSTNo  && this.cuscompanyAddress === '') {
      //console.log(this.cusGSTNo);
      Swal.fire({
        title: 'Error!',
        width: 400,
        text: 'Please enter Company Address',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }
    if (this.cusGSTNo  && this.cuscompanyName === '') {
      //console.log(this.cusGSTNo);
      Swal.fire({
        title: 'Error!',
        width: 400,
        text: 'Please enter Company Name',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }
    // if(this.allTourData?.gst == 'exclusive'){
    //   this.totalAmountToPaid = this.totalAmountToPaidexclusive
    // }
    const orderDetails: any = {
      username: this.allData?.firstname,
      useremail: this.allData?.email,
      user_id: this.webID,
      user_coupon: this.appliedCoupon,
      id: this.tourid,
      payment_status: type === 'booknow' ? this.payment_status : 'Pending',
      cgst: this.allOtherCharges?.cgst,
      sgst: this.allOtherCharges?.sgst,
      igst: this.allOtherCharges?.igst,
      tcs: this.allOtherCharges?.tcs,
      isOuter: this.allData?.stateData?.iso2 === 'MP' ? false : true,
      transctionCharge: this.allOtherCharges?.transactional_charge,
      appliedCoupon: this.discountedPriceByCoupon,
      cusGSTNo: this.cusGSTNo,
      cuscompanyName: this.cuscompanyName,
      cuscompanyAddress: this.cuscompanyAddress,
      bookingNote: this.bookingNote,
      total_amount: this.totalAmountToPaid,
      // total_amount: this.finalPrice,
      pan_card: this.pan_card,
      amount_paid: 0,
      bookedBy: this.user_id,
      amtWithCGSTNewCharges : this.amtWithCGSTNew || 0 ,
      amtWithSGSTNewCharges: this.amtWithSGSTNew ||  0 ,
      amtWithTCSNewCharges : this.amtWithTCSNew || 0 ,
      amtWithIGSTNewCharges : this.amtWithIGSTNew ||  0 ,
      
      // partial_payment:(+this.basicPrice + (this.packPrice ? +this.packPrice : 0) +
      //     (this.packBikePrice ? +this.packBikePrice : 0) + (this.roomPrice ?
      //     +this.roomPrice : 0) +
      //     +this.boardPrice),
      partial_payment: this.allCheckoutTourData?.travel_packageType_data.reduce((total,pack) => {
        if (pack.persons !== 0) return total + Number(pack.packPrice);
        else return total;
      }, 0),
      bookingPrice:this.allTourData?.bookPrice,
      // receipt: 'Receipt #' + (Math.floor(Math.random() * 10000000) + 1),
    };
    if(this.tourtype == 'normal'){
      if (type === 'booknow') {
        orderDetails.transction_Id = 'TI' + (Math.floor(Math.random() * 10000000) + 1);
        orderDetails.order_id = 'OI' + (Math.floor(Math.random() * 10000000) + 1);
        // orderDetails.amount_paid = this.payment_status === 'Partial' ? (+this.allTourData?.bookPrice * +this.allCheckoutTourData?.travel_no_of_traveler) : this.totalAmountToPaid;
        orderDetails.amount_paid = this.payment_status === 'Partial' ? (+this.allTourData?.bookPrice * +this.allCheckoutTourData?.travel_no_of_traveler) : this.totalAmountToPaid ;
      } else {
        orderDetails.payLinkAmount = this.payment_status === 'Partial' ? (+this.allTourData?.bookPrice * +this.allCheckoutTourData?.travel_no_of_traveler) : this.totalAmountToPaid;
      }
    }else if(this.tourtype == 'customized') {
        if (type === 'booknow') {
        orderDetails.transction_Id = 'TI' + (Math.floor(Math.random() * 10000000) + 1);
        orderDetails.order_id = 'OI' + (Math.floor(Math.random() * 10000000) + 1);
        orderDetails.amount_paid = this.payment_status === 'Partial' ? (+this.allTourData?.bookPrice) : this.totalAmountToPaid;
      } else {
        orderDetails.payLinkAmount = this.payment_status === 'Partial' ? (+this.allTourData?.bookPrice) : this.totalAmountToPaid;
      }
    }
   
    // console.log(orderDetails);
    //return;
    this.spinner.show();
    if(this.tourtype == 'normal'){
      // console.log('normal')
      //return
      this.apiService.booking(orderDetails).subscribe((res: any) => {
        if (res.success) {
          this.spinner.hide();
          //console.log(res);
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          if (type === 'booknow') {
            this.router.navigateByUrl('/user-order-history/' + this.webID);
          } else  {
            this.router.navigateByUrl('/order-pay-link-list');
          }
          let notificationData = {
            applyTour_id: this.tourid,
            last_amount_paid : orderDetails.amount_paid,
            is_new: true,
            status: "",
            payment_status: orderDetails.payment_status,
          }
          this.apiService.postNotification(notificationData).subscribe((res: any) => {
            if(res){
              // console.log(res);
            }
          })
        } else {
          this.spinner.hide();
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
        }
      });
    }else  if (this.tourtype == 'customized'){
      // console.log(orderDetails);
      //return;
      this.apiService.bookingcustomized(orderDetails).subscribe((res: any) => {
        if (res.success) {
          this.spinner.hide();
          //console.log(res);
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          if (type === 'booknow') {
            this.router.navigateByUrl('/user-order-history/' + this.webID);
          } else  {
            this.router.navigateByUrl('/order-pay-link-list');
          }
          let notificationData = {
            applyTour_id: this.tourid,
            last_amount_paid : orderDetails.amount_paid,
            is_new: true,
            status: "",
            payment_status: "Paid"
          }
          // console.log(notificationData, "NOTIFICATION!!!");
          this.apiService.postNotification(notificationData).subscribe((res: any) => {
            if(res){
              // console.log(res);
            }
          })
        } else {
          this.spinner.hide();
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
        }
      });
    }
  }

  getCheckoutTourDetails(checkoutId) {
    const getId = {
      id: checkoutId,
    };
    this.apiService.TourId(getId).subscribe((res: any) => {
      if (res.success) {
        // console.log(res);
        this.tour = res.tourData;
        this.allCheckoutTourData = res.getData;

        this.FinalTourPrice=res.getData.pricetourWithoutTaxes;
        this.amtWithCGSTNew =res.getData.amtWithCGSTNewCharges 
        this.amtWithIGSTNew =res.getData.amtWithIGSTNewCharges 
        this.amtWithTCSNew =res.getData.amtWithTCSNewCharges 
        this.amtWithSGSTNew =res.getData.amtWithSGSTNewCharges
        this.totalAmountToPaid =res.getData.finalPrice
        console.log(this.subtotal, this.cgst,this.igst, this.tcsgst, this.sgst);
        
        // console.log(this.allCheckoutTourData)
        this.FinalTourPrice = this.allCheckoutTourData?.pricetourWithoutTaxes
        this.allTourData = res.applyTourbatchId[0]
        this.singlachildprice = this.allCheckoutTourData?.child_price / this.allCheckoutTourData?.child_count
        this.singlainfentprice = this.allCheckoutTourData?.infant_price / this.allCheckoutTourData?.infant_count
        this.finalPrice = +this.allCheckoutTourData?.finalPrice;
        this.tourid = this.allCheckoutTourData._id;
        if (this.allCheckoutTourData.travel_packageType !== '')
          this.getPackageData(this.allCheckoutTourData.travel_packageType);
        if (this.allCheckoutTourData.travel_roomSharing !== '')
          this.getAllRoomData(this.allCheckoutTourData.travel_roomSharing);
        if(this.allCheckoutTourData.tourtype == "normal"){
          this.allTourData = res.applyTourbatchId[0]
        }
        // console.log(this.allTourData?.gst)
        if(this.allCheckoutTourData.tour_type == "normal"){
          this.getBoardingPrice(this.allTourData?.boarding_point_price);
        }
        //this.totalAmountToPaid = this.finalPrice;
        this.calculateAllExtraChargesafterbooking();
      } else {
        //this.spinner.hide();
        // console.log(res);
      }
    });
  }


  calculateAllExtraCharges() {
    // console.log('lll')
    if (
      this.allOtherCharges?.transactional_charge &&
      this.allOtherCharges?.cgst &&
      this.allOtherCharges?.sgst &&
      this.allOtherCharges?.igst &&
      this.allOtherCharges?.tcs 
      ) {
      if (this.allData?.stateData?.iso2 === 'MP') {
        // this.amtWithCGST = this.finalPrice * (+this.allOtherCharges?.cgst * 0.01);
        this.amtWithCGST = (this.allOtherCharges?.cgst * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.cgst * 0.01)+(this.allOtherCharges?.sgst * 0.01))))
        this.amtWithSGST = (this.allOtherCharges?.sgst * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.cgst * 0.01)+(this.allOtherCharges?.sgst * 0.01))))
        this.amtWithTCS  = (this.allOtherCharges?.tcs * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.tcs * 0.01))))
        this.totalAmountToPaid = this.finalPrice;
      } else {
        this.amtWithIGST = (this.allOtherCharges?.igst * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.igst * 0.01))))
        this.amtWithTCS  = (this.allOtherCharges?.tcs * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.tcs * 0.01))))
        this.totalAmountToPaid = this.finalPrice;
        // console.log(this.totalAmountToPaid,this.amtWithTCS,'llll')
      }
    }
      // console.log(this.totalAmountToPaid.toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }));
  }

  calculateAllExtraChargesafterbooking() {
    // console.log('lll')
    if (
      this.allOtherCharges?.transactional_charge &&
      this.allOtherCharges?.cgst &&
      this.allOtherCharges?.sgst &&
      this.allOtherCharges?.igst &&
      this.allOtherCharges?.tcs 
      ) {
      if (this.allData?.stateData?.iso2 === 'MP') {
        // this.amtWithCGST = this.finalPrice * (+this.allOtherCharges?.cgst * 0.01);
        this.amtWithCGST = (this.allOtherCharges?.cgst * 0.01 * (+this.allCheckoutTourData?.TourPrice / (1+(this.allOtherCharges?.cgst * 0.01)+(this.allOtherCharges?.sgst * 0.01))))
        this.amtWithSGST = (this.allOtherCharges?.sgst * 0.01 * (+this.allCheckoutTourData?.TourPrice / (1+(this.allOtherCharges?.cgst * 0.01)+(this.allOtherCharges?.sgst * 0.01))))
        this.amtWithTCS  = (this.allOtherCharges?.tcs * 0.01 * (+this.allCheckoutTourData?.TourPrice / (1+(this.allOtherCharges?.tcs * 0.01))))
        this.totalAmountToPaid = this.finalPrice;
      } else {
        this.amtWithIGST = (this.allOtherCharges?.igst * 0.01 * (+this.allCheckoutTourData?.TourPrice / (1+(this.allOtherCharges?.igst * 0.01))))
        this.amtWithTCS  = (this.allOtherCharges?.tcs * 0.01 * (+this.allCheckoutTourData?.TourPrice / (1+(this.allOtherCharges?.tcs * 0.01))))
        this.totalAmountToPaid = this.finalPrice;
      }
    }
      //console.log(this.totalAmountToPaid.toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }));
  }

  // calculateAllExtraChargesexclusive() {
  //   // console.log('lll')
  //   // console.log(this.finalPrice)
  //   //return
  //   if (
  //     this.allOtherCharges?.transactional_charge &&
  //     this.allOtherCharges?.cgst &&
  //     this.allOtherCharges?.sgst &&
  //     this.allOtherCharges?.igst &&
  //     this.allOtherCharges?.tcs 
  //     ) {
  //     // this.amtWithTransactionalCharge = this.finalPrice * (+this.allOtherCharges?.transactional_charge * 0.01);
  //     if (this.allData?.stateData?.iso2 === 'MP') {
  //       // this.amtWithCGST = this.finalPrice * (+this.allOtherCharges?.cgst * 0.01);
  //       this.amtWithCGST = (this.allOtherCharges?.cgst * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.cgst * 0.01)+(this.allOtherCharges?.sgst * 0.01))))
  //       this.amtWithSGST = (this.allOtherCharges?.sgst * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.cgst * 0.01)+(this.allOtherCharges?.sgst * 0.01))))
  //       this.amtWithTCS  = (this.allOtherCharges?.tcs * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.tcs * 0.01))))
  //       //this.amtWithIGST = (this.allOtherCharges?.igst * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.igst * 0.01))))
  //       if(this.allTourdate?.domesticorInternational == 'domestic'){
  //         if(this.seleactegst?.gst == 'inclusive'){
  //           this.amtWithCGSTNew = (this.allOtherCharges?.cgst * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.cgst * 0.01)+(this.allOtherCharges?.sgst * 0.01))))
  //           this.amtWithSGSTNew = (this.allOtherCharges?.sgst * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.cgst * 0.01)+(this.allOtherCharges?.sgst * 0.01))))
  //           console.log('HEllo',  this.amtWithCGSTNew, this.finalPrice)
  //           this.totalexclusivevalue =  this.amtWithCGST +  this.amtWithSGST

  //           this.FinalTourPrice =this.finalPrice - this.totalexclusivevalue;

  //           console.log('Sub Total',  this.FinalTourPrice );
            
  //         }

  //         if (this.seleactegst?.gst == 'exclusive') {
  
  //           if (this.FinalTourPrice === 0) {
  //             // Use finalPrice for the calculations
  //             this.amtWithCGSTNew = ((this.finalPrice * this.allOtherCharges?.cgst) / 100);
  //             this.amtWithSGSTNew = ((this.finalPrice * this.allOtherCharges?.sgst) / 100);
  //             this.totalexclusivevalue = this.amtWithCGSTNew + this.amtWithSGSTNew;
              
  //             console.log(this.FinalTourPrice, this.finalPrice, this.amtWithCGSTNew, this.totalexclusivevalue, 'jjjjjjjjjjjjjjjjjjjjjj');
            
  //           } else if (this.FinalTourPrice > 0) {
  //             // Use FinalTourPrice for the calculations
  //             this.amtWithCGSTNew = ((this.FinalTourPrice * this.allOtherCharges?.cgst) / 100);
  //             this.amtWithSGSTNew = ((this.FinalTourPrice * this.allOtherCharges?.sgst) / 100);
  //             this.totalexclusivevalue = this.amtWithCGSTNew + this.amtWithSGSTNew;
          
  //             const subtotalexclusive = this.FinalTourPrice + this.totalexclusivevalue;
  //             console.log(subtotalexclusive, ">>>>>>");
  //             this.finalPrice = subtotalexclusive;
  //           }
          
  //           // Final calculation if needed
  //           // this.FinalTourPrice = this.finalPrice + this.totalexclusivevalue;
  //           // console.log('sub total', this.finalPrice);
  //         }
  //         // if(this.seleactegst?.gst == 'exclusive'){
  //         //   this.amtWithCGSTNew = ((this.finalPrice * this.allOtherCharges?.cgst )  / 100) 
  //         //   this.amtWithSGSTNew = ((this.finalPrice * this.allOtherCharges?.sgst )  / 100) 
  //         //   this.totalexclusivevalue =  this.amtWithCGST +  this.amtWithSGST

  //         //   console.log(this.FinalTourPrice,this.finalPrice, this.amtWithCGSTNew, this.amtWithCGST, this.totalexclusivevalue, 'jjjjjjjjjjjjjjjjjjjjjj');
  //         //   this.amtWithCGSTNew = ((this.FinalTourPrice * this.allOtherCharges?.cgst )  / 100) 
  //         //   this.amtWithSGSTNew = ((this.FinalTourPrice * this.allOtherCharges?.sgst )  / 100) 
  //         //   this.totalexclusivevalue =  this.amtWithCGSTNew +  this.amtWithSGSTNew
  //         //   const xyz = this.FinalTourPrice + this.totalexclusivevalue ;
  //         //   console.log(xyz ,">>>>>>");
  //         //   this.finalPrice = xyz

  //         //   // this.FinalTourPrice =this.finalPrice + this.totalexclusivevalue;
  //         //   // console.log('sub total',this.finalPrice);
            
  //         // }
  //       }else if(this.allTourdate?.domesticorInternational == 'international'){
  //         if(this.seleactegst?.gst == 'inclusive' && this.seleactetcs?.tcs == 'inclusive'){
  //           const cgst = Number(this.allOtherCharges?.cgst) || 0;
  //           const sgst = Number(this.allOtherCharges?.sgst) || 0;
  //           const tcs = Number(this.allOtherCharges?.tcs) || 0;
  //           this.totaltax = cgst + sgst + tcs;
  //           this.newprice = (this.totaltax * 0.01 * (+this.finalPrice / (1+(this.totaltax * 0.01))))
  //           // console.log(this.totaltax,  this.newprice);
  //           this.calculatenewprie = this.finalPrice - this.newprice
  //           // console.log(this.calculatenewprie)
  //           this.amtWithCGSTNew = ((this.calculatenewprie * this.allOtherCharges?.cgst )  / 100) 
  //           this.amtWithSGSTNew = ((this.calculatenewprie * this.allOtherCharges?.sgst )  / 100) 
  //           this.amtWithTCSNew = ((this.calculatenewprie * this.allOtherCharges?.tcs )  / 100) 

  //           const subtotalnew =  this.amtWithCGSTNew +  this.amtWithSGSTNew + this.amtWithTCSNew 

  //           this.FinalTourPrice = this.finalPrice - subtotalnew
  //           console.log(this.FinalTourPrice ,">>>");
            
  //         }
  //         console.log(this.amtWithCGSTNew, this.amtWithSGSTNew, this.amtWithTCSNew)
  //         if(this.seleactegst?.gst == 'exclusive' && this.seleactetcs?.tcs == 'exclusive'){
  //           // this.amtWithCGSTNew =  ((this.finalPrice * this.allOtherCharges?.cgst )  / 100) 
  //           // this.amtWithSGSTNew =  ((this.finalPrice * this.allOtherCharges?.sgst )  / 100) 
  //           // this.amtWithTCSNew =  ((this.finalPrice * this.allOtherCharges?.tcs )  / 100) 
  //           // this.totalexclusivevalue =   this.amtWithCGST + this.amtWithSGST + this.amtWithTCS
  //           // console.log( this.amtWithCGSTNew, this.amtWithSGSTNew, this.amtWithTCSNew)

  //           // old code


  //           if (this.FinalTourPrice === 0) {
  //             // Use finalPrice for the calculations
  //             this.amtWithCGSTNew =  ((this.finalPrice * this.allOtherCharges?.cgst )  / 100) 
  //           this.amtWithSGSTNew =  ((this.finalPrice * this.allOtherCharges?.sgst )  / 100) 
  //           this.amtWithTCSNew =  ((this.finalPrice * this.allOtherCharges?.tcs )  / 100) 
  //           this.totalexclusivevalue =   this.amtWithCGST + this.amtWithSGST + this.amtWithTCS
              
  //             console.log(this.FinalTourPrice, this.finalPrice, this.amtWithCGSTNew, this.totalexclusivevalue, 'jjjjjjjjjjjjjjjjjjjjjj');
            
  //           } else if (this.FinalTourPrice > 0) {
  //             // Use FinalTourPrice for the calculations
  //             this.amtWithCGSTNew = ((this.FinalTourPrice * this.allOtherCharges?.cgst) / 100);
  //             this.amtWithSGSTNew = ((this.FinalTourPrice * this.allOtherCharges?.sgst) / 100);
  //             this.amtWithTCSNew =  ((this.FinalTourPrice * this.allOtherCharges?.tcs )  / 100) 
  //             this.totalexclusivevalue = this.amtWithCGSTNew + this.amtWithSGSTNew + this.amtWithTCSNew;
          
  //             const subtotalexclusive = this.FinalTourPrice + this.totalexclusivevalue;
  //             console.log(subtotalexclusive, ">>>>>>");
  //             this.finalPrice = subtotalexclusive;
  //           }
          
            
  //         }if(this.seleactegst?.gst == 'inclusive' && this.seleactetcs?.tcs == 'exclusive'){

  //           console.log("inclusive,exclusive", this.amtWithCGST)
  //           this.finalPrice = this.finalPrice - (this.amtWithCGST*2)
  //           // this.amtWithCGSTNew = (this.allOtherCharges?.cgst * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.cgst * 0.01)+(this.allOtherCharges?.sgst * 0.01))))
  //           // this.amtWithSGSTNew = (this.allOtherCharges?.sgst * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.cgst * 0.01)+(this.allOtherCharges?.sgst * 0.01))))
  //           this.amtWithTCSNew  = ((this.finalPrice * this.allOtherCharges?.tcs )  / 100) 
  //           this.totalexclusivevalue = this.amtWithTCSNew + this.amtWithCGST*2
  //           console.log(this.finalPrice ,this.amtWithTCSNew,this.totalexclusivevalue)

  //         }if(this.seleactegst?.gst == 'exclusive' && this.seleactetcs?.tcs == 'inclusive'){
  //           // console.log("exclusive,inclusive")
  //           // this.finalPrice = this.finalPrice - (this.amtWithTCS)
  //             var newFinalPrice = this.finalPrice - (this.amtWithTCS)
  //             this.newFinalPrice = newFinalPrice.toFixed(2)
  //             this.finalPrice = parseFloat(this.newFinalPrice)
             
          
  //           this.amtWithCGSTNew = ((this.finalPrice * this.allOtherCharges?.cgst )  / 100) 
  //           this.amtWithSGSTNew = ((this.finalPrice * this.allOtherCharges?.sgst )  / 100) 
  //           //this.amtWithTCSNew  = (this.allOtherCharges?.tcs * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.tcs * 0.01))))
  //           this.totalexclusivevalue = this.amtWithCGSTNew*2 + this.amtWithTCS
            
  //           console.log( this.totalexclusivevalue,  this.amtWithSGSTNew, this.amtWithTCS)
     
  //           // console.log(this.finalPrice)
  //         }
  //       }
  //       this.totalAmountToPaid = this.finalPrice;
  //       console.log(this.finalPrice,  this.amtWithCGST)
  //     } else {
  //       this.amtWithIGST = (this.allOtherCharges?.igst * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.igst * 0.01))))
  //       this.amtWithTCS  = (this.allOtherCharges?.tcs * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.tcs * 0.01))))
  //       this.totalAmountToPaid = this.finalPrice;
  //       this.totalAmountToPaid = this.finalPrice;
  //       if(this.allTourdate?.domesticorInternational == 'domestic'){
  //         if(this.seleactegst?.gst == 'inclusive'){
  //           this.amtWithIGSTNew = (this.allOtherCharges?.igst * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.igst * 0.01))))
  //         }
  //         if(this.seleactegst?.gst == 'exclusive'){
  //           this.amtWithIGSTNew = ((this.finalPrice * this.allOtherCharges?.igst )  / 100) 
  //         }
  //         this.totalexclusivevalue =  this.amtWithIGST
  //       } else if(this.allTourdate?.domesticorInternational == 'international'){
  //         if(this.seleactegst?.gst == 'inclusive' && this.seleactetcs?.tcs == 'inclusive'){
  //           this.amtWithIGSTNew = (this.allOtherCharges?.igst * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.igst * 0.01))))
  //           this.amtWithTCSNew  = (this.allOtherCharges?.tcs * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.tcs * 0.01))))
  //           console.log( this.amtWithIGSTNew, this.amtWithTCSNew)
  //         }
  //         if(this.seleactegst?.gst == 'exclusive' && this.seleactetcs?.tcs == 'exclusive'){
  //           this.amtWithIGSTNew = ((this.finalPrice * this.allOtherCharges?.igst )  / 100) 
  //           this.amtWithTCSNew  = ((this.finalPrice * this.allOtherCharges?.tcs )  / 100)
  //           console.log(this.amtWithIGSTNew, this.amtWithTCSNew,this.finalPrice)
  //           //this.totalexclusivevalue =   this.amtWithIGST + this.amtWithTCS
  //           // console.log("exclusive,exclusive")
  //         }if(this.seleactegst?.gst == 'inclusive' && this.seleactetcs?.tcs == 'exclusive'){
  //           // console.log("inclusive,exclusive", this.amtWithIGST)  
  //           this.finalPrice = this.finalPrice - (this.amtWithIGST)
  //           // console.log(this.finalPrice)
  //           //return
  //           //this.amtWithIGSTNew = (this.allOtherCharges?.igst * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.igst * 0.01))))
  //           this.amtWithTCSNew  = ((this.finalPrice * this.allOtherCharges?.tcs )  / 100) 
  //           // console.log(this.finalPrice ,this.amtWithTCSNew, this.amtWithIGST)
  //           this.totalexclusivevalue = this.amtWithTCSNew + this.amtWithIGST
  //         //  console.log(this.totalexclusivevalue , this.amtWithTCSNew + this.amtWithIGST )
  //         }if(this.seleactegst?.gst == 'exclusive' && this.seleactetcs?.tcs == 'inclusive'){
  //           // console.log("exclusive,inclusive")
  //           this.finalPrice = this.finalPrice - (this.amtWithTCS)
  //           // console.log(this.finalPrice)
  //           this.amtWithIGSTNew = ((this.finalPrice * this.allOtherCharges?.igst )  / 100)
  //           // this.amtWithIGSTNew = (this.allOtherCharges?.igst * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.igst * 0.01))))
  //           //this.amtWithTCSNew  = (this.allOtherCharges?.tcs * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.tcs * 0.01))))
  //           // console.log(this.amtWithIGSTNew)
  //           this.totalexclusivevalue = this.amtWithIGSTNew + this.amtWithTCS
  //           console.log(this.finalPrice, this.totalexclusivevalue, this.amtWithIGSTNew, this.amtWithTCS )
  //         }
  //       }
  //       this.totalAmountToPaid = this.finalPrice;
  //     }
  //   }
  // }

  invoiceLogoImgToDataURL() {
    this.apiService
      .imgToData64('../../../assets/img/fdf-01 (1).png')
      .subscribe((blob) => {
        const reader = new FileReader();
        const binaryString = reader.readAsDataURL(blob);
        reader.onload = (event: any) => {
          this.logoImgInDateURL = event.target.result;
        };
        reader.onerror = (event: any) => {
          //console.error('File could not be read: ' + event.target.error.code);
        };
      });
  }
  sendDataToGenerateInvoicerefund() {
 
    // ... (existing code)
    this.order=this.allCheckoutTourData;
    console.log(this.order,">>>>");
    
   const order =this.allCheckoutTourData;
    let ref_amt = (order?.finalPrice - (this.n * this.order.tour_id.bookPrice)) / this.n;
    let remin_amt = (order?.finalPrice - (this.n * this.order.tour_id.bookPrice));
    let alredy_paid = ((order?.amountPaid) - (this.n * this.order.tour_id.bookPrice));
    var couponAmount = (+order?.appliedCoupon) ? order?.appliedCoupon : 0;
    var bpPriceArr = order?.tour_id?.boarding_point_price[0]
    let boardingPoint = order?.travel_boarding_point?.boarding || order.boardingpointustomized;
    // console.log(boardingPoint,">>>>>>");
    
    if (boardingPoint.bp_type == 'single') {
      var boardingPointPrice = +boardingPoint.bp_price[0].singleBoardPrice;
      
    }
   
    //console.log(this.order.tour_id.bookPrice, this.order.partial_payment)
    let myInvoice = `
    <!DOCTYPE html>
    <html>
    <head>
    <style>
      thead tr
      {
          background:#eee!important;
      }
      thead tr th
      {
        background: #eee;
        font-size: 16px
      }
      h5,h6
      {
        font-weight: bold;
        font-size: 16px;
        padding-left: 5px;
      }
      p,span,td
      {
        font-size:16px;
        margin-top:2px!important;
        margin-bottom: 2px!important;
        padding-left: 5px;
      }
      </style>
    </head>
    <body>
      
    <!-- <header class="clearfix" style="display: flex; align-items: flex-start; justify-content: center; position: relative;">
      <div id="logo" style="margin-top: 8px;">
        <img src="${this.logoImgInDateURL}" style="width: 280px; height: 75px;"/>
      </div>
      <div id="company" style="text-align: right;margin-top:-100px;position: absolute;right: 0%;top: 0%;">
        <h2 class="name" style="color:#d14c18;">Quotaion</h2>
        <h6 class="name"  >GLABOL EXPEDITION PVT. LTD.</h6>
        <div>1881 D Sudama Nagar, Indore (m.p) <br/>
        +91 89622 45021 | info@glabol.com<br/>
        </div>
        <div>GST No.: 23AAJCG2630E1Z3</div>
        <div>SAC Code: 998555</div>
      </div>
    </header> -->
    <table style="border: none;" border="0" cellspacing="0" cellpadding="0">
      <tbody style="border: none;">
        <tr style="width: 50.5%;border: none;">
          <td>
          <div id="logo" style="margin-top: 8px;">
            <img src="${this.logoImgInDateURL}" style="width: 225px; height: 60px;"/>
          </div>
          </td>
          <td>
            <div id="company" style="text-align: right;margin-top:-100px;position: absolute;right: 0%;top: 0%;">
              <h2 class="name" style="color:#dc1b5e;">Quotation</h2>
              <h6 class="name"  >GLABOL EXPEDITION PVT. LTD.</h6>
              <div>1881 D Sudama Nagar, Indore (m.p)<br/>
               www.glabol.com | info@glabol.com<br/>
              </div>
              <div>GST No.: 23AAJCG2630E1Z3</div>
              <div>SAC Code: 998555</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <header style="display: flex; justify-content: center; align-items: center">
      <img src="${this.logoImgInDateURL}" style="width: 125px; height: 30px;"/>
      <span>THIS</span>
    </header> -->
    <main>
    <table border="0" cellspacing="0" cellpadding="0" style="width: 100%; margin-bottom: 10px;">
     <tbody style="width: 100%">
<tr style=" width: 33.33%">
        <td rowspan="3">
           
          <h5>Billed To <br> ${order?.user_id?.firstname} ${order?.user_id?.lastname}</h5>
          <span>${order?.user_id?.mobile}</span>
          <span>${order?.user_id?.email ? order?.user_id?.email : ''}</span>`;
 
    if (order?.cusGSTNo) {
      myInvoice += ` <p>GST No.: ${order?.cusGSTNo}</p>`
    }
 
    if (order?.cuscompanyName) {
      myInvoice += `<p>Company Name: ${order?.cuscompanyName}</p>`
    }
    if (order?.cuscompanyAddress) {
      myInvoice += `<p>Company Address: ${order?.cuscompanyAddress}</p>`
    }
 
   
 
    myInvoice += `
   
 </td>
          <td style="text-align: center;">
              <h5> Quotation ID </h5> *********123
            </td>
            <td rowspan="3" style="text-align: center;"><br><br><br>
              Total Amount <h5 style= "color:#dc1b5e;"> ₹${(+order?.finalPrice - +couponAmount)}</h5>
            </td>
        </tr>
      <tr style=" width: 33.33%; border: none;" >
          <td style=" width: 33.33%" >
         
            </td>
        </tr>
        <tr style=" width: 33.33% ;border-top: none;">
          <td style="text-align: center;">
          <h5>Quotation Date </h5>
    ${(this.formattedDate)}
   </td>
        </tr>
    </tbody>
</table>
     
      <table border="0" cellspacing="0" cellpadding="0" style="width: 100%; margin-bottom: 10px;">
        <thead style="width: 100%">
          <tr style="color:#dc1b5e; width: 31%">
          <th class="desc" style="width: ${order?.payment_status == "Partial" ? '45%' : '67.5%'}">Trip Details</th>
          <th class="unit" style="width: ${order?.payment_status == "Partial" ? '25%' : '32.5%'};">No. Of Travellers</th>`
    if (order?.payment_status == "Partial") {
      myInvoice += `<th class="total" style="width: 30%">Due Date</th>`
    }
 
    myInvoice += `</tr>
        </thead>
        <tbody style="width: 100%">
          <tr>
            <td><h5> ${order?.tour_id?.title} Trip</h5>
            <p>Batch: ${order?.travel_date} </p></td>
            <td style="text-align: center;"><br>${order?.travel_no_of_traveler}</td>`
    if (order?.payment_status == "Partial") {
      myInvoice += `<td style="text-align: center;"><br><span>${new Date(order?.tourReminderEndDate).toLocaleDateString().split("/")[1]}/${new Date(order?.tourReminderEndDate).toLocaleDateString().split("/")[0]}/${new Date(order?.tourReminderEndDate).toLocaleDateString().split("/")[2]}</span></td>`
    }
 
    myInvoice += `</tr>
        </tbody>
      </table>
      <table border="0" cellspacing="0" cellpadding="0" style="width: 100%; margin-bottom: 10px;">
                    <thead style="width: 100%">
                        <tr style="color:#dc1b5e; width: 31%">
                          <!-- <th class="no" style="width: 7%">S.No.</th> -->
                          <th class="desc" style="width: 40%">Descripiton</th>
                          <!-- <th class="unit" style="width: 15%"></th> -->
                          <th class="unit" style="width: 15%;">Quantity</th>
                          <th class="total" style="width: 15%">Price</th>
                          <th class="total" style="width: 30%">Total</th>
                        </tr>
                      </thead>
                      <tbody style="width: 100%">
                        <!-- <tr>
                          <td><h5> ${order?.tour_id?.title} Trip</h5>
                          <p>Batch: ${order?.travel_date} </p></td>
                          <td style="text-align: center;"><br>${order?.travel_no_of_traveler}</td>
                          <td></td>
                        </tr>
                        <tr style="color:#dc1b5e; width: 31%">
                          <td class="desc" style="width: 45%"></td>
                          <td class="unit" style="width: 25%; font-weight: bold;">Quantity</td>
                          <td class="total" style="width: 30%; font-weight: bold;">Price</td>
                        </tr> -->
                       
                        <tr style="width: 20%">
                          <!-- <td class="no" style="width: 7%">01</td> -->
                          <td class="desc" style="width: 38%;line-spacing:0.5px;">
                            <!-- <h5> ${order?.tour_id?.title} Trip</h5>
                            <p>Batch: ${order?.travel_date} </p> -->`
    if (order?.travel_packageType_data?.length !== 0) {
      myInvoice += `<h6 style="margin: 0px;"><u>Package Type</u></h6>`
 
      for (let packData of order?.travel_packageType_data) {
        if (packData.persons !== 0) {
 
          myInvoice += `<span>`;
          if (packData?.type !== 'normal') {
            myInvoice += `${packData.type} X `;
          }
          myInvoice += `${packData.packType}`;
          myInvoice += `</span>`;
        }
      }
    }
//
 
 
if (order?.child_count) {
  // myInvoice += `<h6 style="margin: 0px; margin-top: 5px;">Room Sharing Type</h6>`
 
  function RoomSharing() {
   
      if (order?.child_count) {
        return myInvoice += `<h6 style="margin: 0px; margin-top: 5px;"><u>Child/Infant</u></h6>`;
      }
   
  }
  RoomSharing();
 
    if (order?.child_count ) {
      myInvoice += `<span>`;
      myInvoice += `Child`;
      myInvoice += `</span>`;
   
  }
  if (order?.infant_count) {
    myInvoice += `<span>`;
    myInvoice += `Infant`;
    myInvoice += `</span>`;
 
}
}
 
//
    if (order?.travel_roomSharing_data?.length !== 0) {
      // myInvoice += `<h6 style="margin: 0px; margin-top: 5px;">Room Sharing Type</h6>`
 
      function RoomSharing() {
        for (let roomData of order?.travel_roomSharing_data) {
          if (roomData.persons !== 0) {
            return myInvoice += `<h6 style="margin: 0px; margin-top: 5px;"><u>Room Sharing Type</u></h6>`;
          }
        }
      }
      RoomSharing();
      for (let roomData of order?.travel_roomSharing_data) {
        if (roomData.persons !== 0) {
          myInvoice += `<span>`;
          myInvoice += `${roomData.roomType}`;
          myInvoice += `</span>`;
        }
      }
    }
    let boardingpoint = order?.travel_boarding_point?.boarding || order?.boardingpointustomized || 'Not available';

    myInvoice += `
      <h6 style="margin: 0; margin-top: 5px;"><u>Boarding Point</u></h6>
      <span>${boardingpoint}</span>`;
    if (order?.boardingType) {
      myInvoice += `
                            <h6 style="margin: 0px; margin-top: 5px;"><u>Boarding Point Type</u></h6>
                              <span>${order?.boardingType}</span>`
    }
 
    myInvoice += `
                          </td>
                          <!-- <td style="text-align: center;margin-top:5px">${order?.travel_no_of_traveler}</td> -->
                          <td class="unit" style="text-align: center;">
                          <!-- <p>&nbsp;</p>
                          <p>&nbsp;</p> -->
                          <br>
                          `;
 
    if (order?.travel_packageType_data?.length !== 0) {
      // myInvoice += `<br>`
 
      for (let packData of order?.travel_packageType_data) {
        if (packData.persons !== 0) {
 
          // myInvoice += `₹${packData.packPrice} x ${packData.persons}`;
          myInvoice += `<div>${packData.persons}<div>`;
          // myInvoice += `<br>`;
        }
      }
    }
 
// -----------------------
 
if (order?.child_count) {
  myInvoice += `<br>`
 
    if (order?.child_count) {
 
      // myInvoice += `₹${roomData.roomPrice} x ${roomData.persons}`;
      myInvoice += `<div>${order?.child_count}</div>`;
      // myInvoice += `<br> <br>`;
   
  }
  if (order?.infant_count) {
 
    // myInvoice += `₹${roomData.roomPrice} x ${roomData.persons}`;
    myInvoice += `<div>${order?.infant_count}</div>`;
    // myInvoice += `<br> <br>`;
 
}
  function forBr() {
   
      if (order?.child_count ||order?.infant_count) {
        return myInvoice += `<div>&nbsp;</div>`;
     
    }
 
  }
  forBr()
  // myInvoice += `<br>`;
}
 
// -------------------------
    let forStyle = false;
    if (order?.travel_roomSharing_data?.length !== 0) {
      myInvoice += `<br>`
      for (let roomData of order?.travel_roomSharing_data) {
        if (roomData.persons !== 0) {
 
          // myInvoice += `₹${roomData.roomPrice} x ${roomData.persons}`;
          myInvoice += `<div>${roomData.persons}</div>`;
          // myInvoice += `<br> <br>`;
        }
      }
      function forBr() {
        for (let roomData of order?.travel_roomSharing_data) {
          if (roomData.persons !== 0) {
            return myInvoice += `<div>&nbsp;</div>`;
          }
        }
      }
      forBr()
      // myInvoice += `<br>`;
    }
    myInvoice += `        
                        <!-- <br>
                        <span>₹${boardingPointPrice} x ${order?.travel_no_of_traveler}<br> -->
                        <span>${order?.old_travel_no_of_traveler}<br>
                        </td>
                        <td>
                        <br>
                        <!-- <p>&nbsp;</p>
                        <p>&nbsp;</p> -->`;
    if (order?.travel_packageType_data?.length !== 0) {
      for (let packData of order?.travel_packageType_data) {
        if (packData.persons !== 0) {
 
          // myInvoice += `₹${Number(packData.packPrice) * Number(packData.persons)}`;
          myInvoice += `<div>₹${packData.packPrice} x ${packData.persons}</div>`;
          // myInvoice += `<br>`;
        }
      }
    }
//
 
if (order?.child_count) {
  myInvoice += `<br>`
 
  if (order?.child_count) {
 
    // myInvoice += `₹${Number(roomData.roomPrice) * Number(roomData.persons)}`;
    myInvoice += `<div>₹${order?.child_price} x ${order?.child_count}</div>`;
    // myInvoice += `<br>`;
 
}
    if (order?.infant_count) {
 
      // myInvoice += `₹${Number(roomData.roomPrice) * Number(roomData.persons)}`;
      myInvoice += `<div>₹${order?.infant_price} x ${order?.infant_count}</div>`;
      // myInvoice += `<br>`;
   
  }
 
    function forBr() {
    
        if (order?.child_count||order?.infant_count) {
          return myInvoice += `<div>&nbsp;</div>`;
      
      }
    
    }
    forBr()
  // myInvoice += `<br><br>`
}
 
    //
    if (order?.travel_roomSharing_data?.length !== 0) {
      myInvoice += `<br>`
 
      for (let roomData of order?.travel_roomSharing_data) {
        if (roomData.persons !== 0) {
 
          // myInvoice += `₹${Number(roomData.roomPrice) * Number(roomData.persons)}`;
          myInvoice += `<div>₹${roomData.roomPrice} x ${roomData.persons}</div>`;
          // myInvoice += `<br>`;
        }
      }
      function forBr() {
        for (let roomData of order?.travel_roomSharing_data) {
          if (roomData.persons !== 0) {
            return myInvoice += `<br><div>&nbsp;</div>`;
          }
        }
      }
      forBr()
      // myInvoice += `<br><br>`
    }
    // myInvoice += `<br><span>₹${Number(boardingPointPrice) * Number(order?.travel_no_of_traveler)}<br>`;
    let boardingTypeInfo = order?.travel_boardingPrice || order?.boardingTypepricecustomized;
if (boardingTypeInfo){
    myInvoice += `<div>₹${boardingTypeInfo } x ${order?.old_travel_no_of_traveler}</div>
                          </td>`;
 
    myInvoice += `
                          <td>
                          <br>
                          <!-- <p>&nbsp;</p>
                          <p>&nbsp;</p> -->`;
}
 
    if (order?.travel_packageType_data?.length !== 0) {
      for (let packData of order?.travel_packageType_data) {
        if (packData.persons !== 0) {
 
          // myInvoice += `₹${Number(packData.packPrice) * Number(packData.persons)}`;
          myInvoice += `<div>₹${+packData.packPrice * +packData.persons}</div>`;
          // myInvoice += `<br>`;
        }
      }
    }
 
// --------------
 
if (order?.child_count) {
  myInvoice += `<br>`
 
 
    if (order?.child_count) {
      // myInvoice += `₹${Number(roomData.roomPrice) * Number(roomData.persons)}`;
      myInvoice += `<div>₹${+order?.child_count * +order?.child_price}</div>`;
   
  }
 
  if (order?.infant_count) {
    // myInvoice += `₹${Number(roomData.roomPrice) * Number(roomData.persons)}`;
    myInvoice += `<div>₹${+order?.infant_count * +order?.infant_price}</div>`;
 
}
  function forBr() {
 
      if (order?.child_count|| order?.infant_count) {
        return myInvoice += `<div>&nbsp;</div>`;
     
    }
   
  }
  forBr()
  // myInvoice += `<br><br>`;
}
 
    // ------------------
    if (order?.travel_roomSharing_data?.length !== 0) {
      myInvoice += `<br>`
 
      for (let roomData of order?.travel_roomSharing_data) {
        if (roomData.persons !== 0) {
 
          // myInvoice += `₹${Number(roomData.roomPrice) * Number(roomData.persons)}`;
          myInvoice += `<div>₹${+roomData.roomPrice * +roomData.persons}</div>`;
        }
      }
      function forBr() {
        for (let roomData of order?.travel_roomSharing_data) {
          if (roomData.persons !== 0) {
            return myInvoice += `<br><div>&nbsp;</div>`;
          }
        }
      }
      forBr()
      // myInvoice += `<br><br>`;
    }
    // myInvoice += `<br><span>₹${Number(boardingPointPrice) * Number(order?.travel_no_of_traveler)}<br>`;
    let boardingTypeInfototel = order?.travel_boardingPrice || order?.boardingTypepricecustomized;
    if (boardingTypeInfo) {
    myInvoice += `<div>₹${boardingTypeInfo * +order?.old_travel_no_of_traveler}</div>
                          </td>`;}
 
    myInvoice += `
                      </tr>
                    </tbody>
                    <tfoot style="width:100%">
                   
                    <tr style="width:20%">
                      <td></td>
                      <td colspan="2">Final Price</td>
                      <td class="total">₹${order?.finalPrice}</td>
                    </tr>
                    <tr style="width:20%">
                      <td></td>
                      <td colspan="2">Coupon Discount</td>
                      <td>₹${+couponAmount}</td>
                    </tr>
                 
                      <tr style="width:20%">
                        <td></td>
                        <td colspan="2">Total Package Cost</td>
                        <td>₹${(+order?.finalPrice - +couponAmount).toFixed(2)}</td>
                      </tr>
                      <tr style="width:20%">
                    <td>*Tax Included in Amount</td>
                       
                          <td style="font-weight: bold" colspan="2">Grand Total</td>
                          <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount)}</td>
                        </tr>
                        <!-- <tr style="width:20%">
                        <td></td>
                        <td colspan="2">Total Amount Paid</td>
                        <td>₹${(order?.totalAmount)}</td>
                      </tr> -->`;
 
 
 
    // console.log(ref_amt * (this.n - order?.travel_no_of_traveler), remin_amt - alredy_paid)
    // ref_amt!==(remin_amt-alredy_paid)){
    let traveler = Math.round((remin_amt - alredy_paid) / ref_amt);
    // console.log(traveler, this.n - traveler, "---", this.n - order?.travel_no_of_traveler)
    // console.log(this.n, this.order.tour_id.bookPrice, this.order.finalPrice, this.totalPayAmt)
    // console.log(order?.travel_no_of_traveler !== this.n, "&&", order?.amountPaid, this.totalpaidAddon, "&& ", order?.isComplete <= 1)
    if ((order?.travel_no_of_traveler !== this.n) && ((order?.amountPaid - this.totalpaidAddon) < order?.totalAmount) && (order?.isComplete <= 1)) {
 
      myInvoice += `<tr style="width:20%">
                          <td>*Tax Included in Amount</td>
                       
                          <td style="font-weight: bold" colspan="2">Grand Total</td>
                          <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount)}</td>
                        </tr>`
                        
      if (order?.amountPaid === this.totalPayAmt) {
        myInvoice += `
                          <tr style="width:20%">
                          <td>*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
                          <td colspan="2">Amount after Member Cancel</td>
                          <td>₹${(order?.totalAmount)}</td>
                        </tr>
                          <!-- <tr style="width:20%">
                          <td></td>
                          <td colspan="2">Amount Paid</td>
                          <td>₹${(order?.totalAmount)}</td>
                        </tr> -->
                        `;
        
      }
      else if (order?.amountPaid !== this.totalPayAmt + (this.n * this.order.tour_id.bookPrice)) {
        myInvoice += `
                        <tr style="width:20%">
                        <td>*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
                        <td colspan="2">Amount after Member Cancel</td>
                        <td>₹${((order?.totalAmount))}</td>
                      </tr>
                        <!-- <tr style="width:20%">
                        <td></td>
                        <td colspan="2">Amount Paid</td>
                        <td>₹${((order?.totalAmount))}</td>
                      </tr> -->
                      `;
      }
      else {
        myInvoice += `
                        <tr style="width:20%">
                        <td>*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
                        <td colspan="2">Amount after Member Cancel</td>
                        <td>₹${((order?.totalAmount))}</td>
                      </tr>
                        <!-- <tr style="width:20%">
                        <td></td>
                        <td colspan="2">Amount Paid</td>
                        <td>₹${((order?.totalAmount))}</td>
                      </tr> -->
                      `;
      
      }
    
    }
    else if (order.payment_status === "Cancelled") {
      if (this.n - (this.n - order?.travel_no_of_traveler) === 1) {
        myInvoice += `<tr style="width:20%">
                                    <td>*Tax Included in Amount</td>
                                    <td style="font-weight: bold" colspan="2">Grand Total</td>
                                    <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount)}</td>
                                  </tr>
                                  <tr style="width:20%">
                                    <td></td>
                                    <td colspan="2">Total Amount to Pay</td>
                                    <td>₹0</td>
                                  </tr>`;
      } else if (this.n != order?.travel_no_of_traveler) {
        myInvoice += `<tr style="width:20%">
                <td>*Tax Included in Amount</td>
                <td style="font-weight: bold" colspan="2">Grand Total</td>
                <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount)}</td>
          </tr>
 
              <tr style="width:20%">
              <td>*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
          <td colspan="2">Amount after Member Cancel</td>
          <td>₹${(order?.totalAmount)}</td>
          </tr>`}
      else {
        myInvoice += `<tr style="width:20%">
                                    <td>*Tax Included in Amount</td>
                                    <td style="font-weight: bold" colspan="2">Grand Total</td>
                                    <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount)}</td>
        </tr>
 
                                 <!-- <tr style="width:20%">
                                  <td>*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
                          <td colspan="2">Amount after Member Cancel</td>
                          <td>₹${(order?.totalAmount)}</td>
                          </tr> -->
                                 <!-- </tr>
                                  <tr style="width:20%">
                                    <td></td>
                                    <td colspan="2">Total Amount to Pay</td>
                                    <td>₹${(order?.totalAmount - order?.amountPaid)}</td>
                                  </tr> -->`;
      }
    }
    //add on after full payment
    else if (((order?.amountPaid - this.totalpaidAddon) > order?.totalAmount || order?.isComplete > 1) && (this.n == order?.travel_no_of_traveler)) {
      if (this.n == order?.travel_no_of_traveler) {
        myInvoice += `<tr style="width:20%">
                      <td>*Tax Included in Amount</td>
                      <td style="font-weight: bold" colspan="2">Grand Total</td>
                      <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount)}</td>
                    </tr>
                    <!-- <tr style="width:20%">
                      <td></td>
                      <td colspan="2">Total Amount Paid</td>
                      <td>₹${(order?.totalAmount)}</td>
                    </tr> -->`;
      }
    }
 
    //ends
 
    else if (((order?.amountPaid - this.totalpaidAddon) >= order?.totalAmount || order?.isComplete > 1) && (this.n !== order?.travel_no_of_traveler)) {
 
      myInvoice += `<tr style="width:20%">
                                      <td>*Tax Included in Amount</td>
                                      <td style="font-weight: bold" colspan="2">Grand Total</td>
                                      <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount)}</td>
                                    </tr>
                                    <tr style="width:20%">
                                    <td>*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
                        <td colspan="2">Amount after Member Cancel</td>
                        <td>₹${(order?.totalAmount)}</td>
                      </tr>
                                    <!-- <tr style="width:20%">
                                    <td></td>
                        <td colspan="2">Amount Paid</td>
                        <td>₹${(order?.totalAmount)}</td>
                      </tr> -->
                    `;
    }
    else {
      myInvoice += `<tr style="width:20%">
                    <td>*Tax Included in Amount</td>
                       
                          <td style="font-weight: bold" colspan="2">Grand Total</td>
                          <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount)}</td>
                        </tr>
                        <!-- <tr style="width:20%">
                        <td></td>
                        <td colspan="2">Total Amount Paid</td>
                        <td>₹${(order?.totalAmount)}</td>
                      </tr> -->`;
 
    }
 
    if (order?.isRefundedOnCancel) {
      
      myInvoice += `<tr style="width:20%">
                          <td></td>
                          <td colspan="2">Refund Amount</td>
                          <td>₹${order?.refundAmount}</td>
                        </tr>`;
    }
 
    myInvoice += `</tfoot>
                    </table>`
 
    myInvoice += `
        <div>TERMS & CONDITIONS:</div>
          <div class="notice">
            1. All disputes are subjected to Indore Jurisdiction only.
          </div>
       
      </div>`

        
      myInvoice += `
      <div style = "height:99%; page-break-before: always;border:2px solid white;" > 
        <div style = "border: 2px solid rgb(184, 113, 80); padding: 0 0 10px 0; margin: 1% 4px;" > 
          <div style = "border-bottom: 2px solid rgb(184, 113, 80); " > 
            <h2 style = "margin: 3% 0; padding: 0;text-align: center;"> ITINERARY </h2>
          </div>`;
  
          let dayCount = 1;
          for (let i = 0; i < this.tour.Itinerary.length; i++) {
            const day = this.tour.Itinerary[i]; 
            const pageBreak = (i !== 0 && i % 2 === 0) ? 'page-break-before: always; margin-top: 50px;' : '';
            myInvoice += `<div style = " border-bottom:2px solid rgb(184, 113, 80); ">
              <div style=" padding: 10px; margin: 1% 4px; ${pageBreak}">
                <h2 style="color: red; text-decoration: underline;">Day ${dayCount++}: ${day.Title}</h2>
                <p>${day.Description}</p>
                <ul>`;
                  if (day.Properties && day.Properties.length > 0) {
                    for (const property of day.Properties) {
                      myInvoice += `<li>${property}</li>`;
                    }
                  }
                  myInvoice += `</ul>`;
                // htmlContent += `<div>`;
                //   if (day.Itineraryimage && day.Itineraryimage.length > 0) {
                //     for (const image of day.Itineraryimage) {
                //       const imagePath = `https://glabolapi.swapinfotech.com/${image.src}`;
                //       const imageResponse = await axios.get(imagePath, {
                //         responseType: "arraybuffer",
                //       });
                //       const imageBase64 = Buffer.from(imageResponse.data).toString(
                //         "base64"
                //       );
                //       const base64ImageSrc = `data:image/${image.orgName
                //         .split(".")
                //         .pop()};base64,${imageBase64}`;
                //       htmlContent += `<img src="${base64ImageSrc}" alt="${image.orgName}" style="width:175px;height:auto; margin-right: 10px; margin-bottom: 10px;" >`;
                //     }
                //   }
                // htmlContent += `</div>`; 
                myInvoice += `</div>`; 
                myInvoice += `</div>`; 
          }
          myInvoice += `<div style="border-bottom:2px solid rgb(184, 113, 80);">
            <div style="padding: 10px; margin: 1% 4px; ">
              <h2 style="margin: 10px 0px; padding: 0; color: red;text-decoration: underline; text-align:center;">${this.tour.inculssion}</h2>
              <p style="margin: 30px 0px; padding: 0;">${this.tour.summary}</p>
            </div>
          </div>`;
          myInvoice += `<div style="border-bottom:2px solid rgb(184, 113, 80);">
            <div style="padding: 10px; margin: 1% 4px; ">
              <h2 style="margin: 10px 0px; padding: 0; color: red;text-decoration: underline; text-align:center;">${this.tour.exculssion}</h2>
              <p style="margin: 30px 0px; padding: 0;">${this.tour.summaryExc}</p>
            </div>
          </div>`;  
          myInvoice += `<div style="border-bottom:2px solid rgb(184, 113, 80);">
            <div style="padding: 10px; margin: 1% 4px; ">
              <h2 style="margin: 10px 0px; padding: 0; color: red;text-decoration: underline; text-align:center;">Cancellation Policy</h2>
              <p style="margin: 30px 0px; padding: 0;">${this.tour.cancellationPolicy}</p>
            </div>
          </div>`;  
          myInvoice += `<div style="max-width: 800px; page-break-before: always;
              margin-top: 50px ; 
              background-color: #fff;
              padding: 20px;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);">
            <h1 style="text-align: center;color: #333">Why Glabol?</h1>
            <br>
            <p style="line-height: 1.6;
              color: #666;">Glabol's trips are exclusively for <span class="highlight">18-45 year olds</span>. So you get a chance to travel with a group of young people just like you, who are fun and easy to connect to.</p>
              <br>
              <br>
            <p style="line-height: 1.6;
              color: #666;">We know how hard it can be to convince friends for a trip and make plans which never see the light of day. With us, you can join the trip solo too if others back out. You may be going with a bunch of strangers but you will come back with a family.</p>
              <br>
              <br>
            <p style="line-height: 1.6;
                color: #666;">Our trips are chill and comprise people who like to live in the moment, appreciate nature, and make new friends. The kind of people you will meet and make friends with is what makes it so special.</p>
                <br>
                <br>
            <div style="text-align: center;margin: 20px 0;">
              <p style="line-height: 1.6; color: #666;"><span class="highlight">ISO 1900:2015 CERTIFIED</span></p>
            </div>
            <p style="line-height: 1.6;
                color: #666;"><span style="color: #ff5722;
                font-weight: bold;">Travel Now, Pay Later.</span></p>
                <br>
            <p style="line-height: 1.6;
              color: #666;"><strong>“Work, Travel, Save, Repeat”</strong></p>
              <br>
            <div style="text-align: center;
              padding: 10px;
              background-color: #333;
              position: absolute;
              left:0;
              color: #fff;
              width: 100%;">
              &copy; 2024 Glabol. All rights reserved.
            </div>
          </div>
        </div>
    </main>
  </body>
</html>
    `;
 
    //html to pdf format
    var html = htmlToPdfmake(myInvoice, { tableAutoSize: true });
    const documentDefinition = { content: html };
 
    // Print PDF
    pdfMake.createPdf(documentDefinition).open();
    // console.log(html)
}
}