<section class="content">
      <div class="row">
            <div class="col-12">
                  <div class="card">
                        <ul class="nav nav-pills mb-3 nav-tabs justify-content-around" id="pills-tab" role="tablist">
                              <li class="nav-item col-4 p-0 text-center" role="presentation">
                                    <a class="nav-link active" id="pills-contact-tab" data-toggle="pill"
                                          href="#pills-contact" role="tab" aria-controls="pills-contact"
                                          aria-selected="true">Tour Details</a>
                              </li>
                              <li class="nav-item col-4 p-0 text-center" role="presentation">
                                    <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile"
                                          role="tab" aria-controls="pills-profile" aria-selected="false">Payment
                                          Detail</a>
                              </li>

                              <div class="btn-group">
                                    <button type="button" class="btn btn-info"
                                          [routerLink]="['/user-order-history/'+data?.user_id?._id]">
                                          Order History
                                    </button>
                              </div>
                        </ul>

                        <div class="tab-content" id="pills-tabContent">
                              <div class="tab-pane fade show active" id="pills-contact" role="tabpanel"
                                    aria-labelledby="pills-contact-tab">
                                    <div class="">
                                          <div class="card-body">
                                                <div class="row">
                                                      <div class="form-group col-md-4">
                                                            <div>Tour Name</div>
                                                            <div class="form-control">{{data?.tour_id?.title}}</div>
                                                      </div>
                                                      <div class="form-group col-md-4">
                                                            <div>Name</div>
                                                            <div class="form-control">{{data?.user_id?.firstname}}
                                                            </div>
                                                      </div>
                                                      <div class="form-group col-md-4">
                                                            <div>lastname</div>
                                                            <div class="form-control">{{data?.user_id?.lastname}} </div>
                                                      </div>
                                                      <div class="form-group col-md-4">
                                                            <div>Mobile</div>
                                                            <div class="form-control">{{data?.user_id?.mobile}} </div>
                                                      </div>
                                                      <div class="form-group col-md-4">
                                                            <div>Payment Status</div>
                                                            <div class="form-control"> {{data?.payment_status}}</div>
                                                      </div>

                                                      <div class="form-group col-md-4">
                                                            <div>Date & Time</div>
                                                            <div class="form-control">{{data?.created_at | date:"d/M/yy,
                                                                  h:mm a"}}</div>
                                                      </div>

                                                      <div class="form-group col-md-4">
                                                            <div>Tour Date</div>
                                                            <div class="form-control"> {{data?.tourDate2}}</div>
                                                      </div>
                                                      <div class="form-group col-md-4">
                                                            <div>No. Of Child</div>
                                                            <div class="form-control">{{data?.
                                                                  child_count
                                                                  }} </div>
                                                      </div>
                                                      <div class="form-group col-md-4">
                                                            <div>No. Of infant</div>
                                                            <div class="form-control"> {{data?.infant_count}}</div>

                                                      </div>
                                                      <div class="form-group col-md-4">
                                                            <div>No. Of Member</div>
                                                            <div class="form-control">
                                                                  {{data?.old_travel_no_of_traveler}} </div>
                                                      </div>
                                                      <br />
                                                      <br />
                                                      <div class="form-group col-md-4">
                                                            <div>Agent Name</div>
                                                            <div class="form-control"> 
                                                                  {{
                                                                        (data?.bookedBy?.firstname || data?.bookedBy?.lastname)
                                                                        ? (data?.bookedBy?.firstname || 'Web') + ' ' + (data?.bookedBy?.lastname || 'Web')
                                                                        : 'Web'
                                                                        }}
                                                            </div>


                                                                 
                                                      </div>
                                                      <div class="form-group col-md-4">
                                                            <div>Order ID</div>
                                                            <div class="form-control">{{data?.order_id}} </div>
                                                      </div>

                                                      <div class="form-group col-md-4">
                                                            <div>Boarding Point</div>
                                                            <div class="form-control">{{data?.travel_boarding_point?.boarding}} </div>

                                                      </div>

                                                      <div class="form-group col-md-4">
                                                            <label>Package Types</label>
                                                            <select class="form-control"
                                                                  [(ngModel)]="selectedPackageTypes" multiple>
                                                                  <option
                                                                        *ngFor="let data of data?.travel_packageType_data"
                                                                        [value]="data?.packType">{{data?.packType}}
                                                                  </option>
                                                            </select>
                                                      </div>
                                                      <div class="form-group col-md-4">
                                                            <label>Room Sharing Type</label>
                                                            <select class="form-control"
                                                                  [(ngModel)]="selectedPackageTypes" multiple>
                                                                  <option
                                                                        *ngFor="let data of data?.travel_roomSharing_data"
                                                                        [value]="data?.packType">{{data?.roomType}}
                                                                  </option>
                                                            </select>

                                                      </div>
                                                      <br />
                                                      <br />
                                                      <div class="form-group col-md-4">
                                                            <div>Transcation ID</div>

                                                            <div class="form-control">{{data?.transction_Id}} </div>
                                                      </div>

                                                      <div class="form-group col-md-4">
                                                            <div>Total Amount</div>
                                                            <div class="form-control">{{data?.totalAmount}} </div>

                                                      </div>
                                                      <div class="form-group col-md-4">
                                                            <div>Amount Paid</div>
                                                            <div class="form-control"> {{data?.amountPaid}}</div>
                                                      </div>
                                                      <div class="form-group col-md-4">
                                                            <div>Total Remaining Amount</div>
                                                            <div class="form-control">{{data?.totalAmount -
                                                                  data?.amountPaid}}</div>
                                                      </div>

                                                      <br />


                                                </div>
                                          </div>
                                    </div>
                              </div>

                              <div class="tab-pane fade" id="pills-profile" role="tabpanel"
                                    aria-labelledby="pills-profile-tab">
                                    <div class="row">
                                          <div class="col-12">
                                                <div class="">
                                                      <div class="card-body">
                                                            <table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
                                                                  <thead>
                                                                        <tr>
                                                                              <th>S. No.</th>
                                                                              <th>Name</th>
                                                                              <th>Transcation Date And Time</th>
                                                                              <th>Total Amount</th>
                                                                              <th>Amount Paid</th>
                                                                              <th>Remainging Amount</th>
                                                                              <th>Invoice</th>
                                                                        </tr>
                                                                  </thead>
                                                                  <tbody *ngIf="allData?.length != 0">
                                                                        <tr *ngFor="let data of allData; let i = index">
                                                                              <td>{{ i + 1 }}</td>
                                                                              <td>{{data?.user_id?.firstname }}</td>
                                                                              <td>{{data?.created_at | date:"d/M/yy,h:mm a"}}</td>
                                                                              <td>{{ data?.totalAmount}}</td>
                                                                              <td>{{ data?.amountPaid }}</td>
                                                                              <td>{{data?.totalAmount -
                                                                                    data?.amountPaid}}</td>
                                                                              <td>
                                                                                    <div class="btn-group">
                                                                                          <button type="button"
                                                                                                class="btn btn-info"
                                                                                                (click)="openLink(data.pdf_url)">
                                                                                                <i
                                                                                                      class="far fa-file-pdf">
                                                                                                      Invoice</i></button>
                                                                                    </div>
                                                                              </td>

                                                                        </tr>
                                                                  </tbody>
                                                                
                                                            </table>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
</section>