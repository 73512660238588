import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { forEach } from 'jszip';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  allTour: any;
  allOrder: any = {};
  allUser: any = [];
  render: number;
  allPackageData: any;
  FinalBooking: boolean = false;
  showrow: boolean = false;
  dtOptions: any = {};
  private isDtInitialized: boolean = false;
  totalUnpaidAddon = 0;
  public value: boolean = false;
  public selectFinancialyear: any;
  public adventure_type: any;
  public getFinancialYear: Array<any> = [];
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  @ViewChild('dateSchedule') dateSchedule: ElementRef;
  @ViewChild('status') status: ElementRef;
  @ViewChild('month') month: ElementRef;
  data: any;
  allAdve: any;
  tourtype: any;
  domesticd: any;
  selectedAdventures: any
  alldesti: any;
  destination: string;
  showtour: boolean;
  tourData: any;
  allDate: any;
  TourID: any;
  AgentID: any;
  allData: any;
  dateForm: FormGroup;
  startDate: string | null = null;
  endDate: string | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {

    this.dateForm = this.fb.group({
      start_date1: ['', Validators.required],
      end_date1: ['', Validators.required]
    });
    this.dateForm.get('start_date1')?.valueChanges.subscribe((value: string) => {
      this.startDate = value;
      console.log("Start Date Selected:", this.startDate);
    });

    this.dateForm.get('end_date1')?.valueChanges.subscribe((value: string) => {
      this.endDate = value;
      console.log("End Date Selected:", this.endDate);
    });
  
    var yearsLength = 5;
    this.selectFinancialyear = ""
    this.selectedAdventures = ""
    var currentYear = new Date().getFullYear();
    for(var i = 0; i < 5; i++){
      var next = currentYear+1;
      var year = currentYear + '-' + next.toString().slice(-2);
      // $('#financialYear').append(new Option(year, year));
      this.getFinancialYear.unshift(year)
      currentYear--;
    }
     this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: true,
      pageLength: 10,
      dom: 'Bfrtip',
      buttons: [
        { extend: 'excel'  },
        { extend: 'copy' },
        { extend: 'csv' },
        { extend: 'print' },
        { extend: 'pdfHtml5', footer: true, pageSize: 'A3', }
      ]
      //buttons: ['excel']
    };
    // this.render = 1;
    this.activeTour();
    this.getAllPackageData();
    this.userreport();
    this.adventure();
    this.desti();
    this.admin();
  }



  dateValidator(startDate: string) {
    return (control: any) => {
      const endDate = control.value;
      if (endDate && new Date(endDate) < new Date(startDate)) {
        return { invalidEndDate: true };
      }
      return null;
    };
  }

  onSubmit(): void {
    if (this.dateForm.valid) {
      const { start_date1, end_date1 } = this.dateForm.value;
      console.log(`Start Date: ${start_date1}`);
      console.log(`End Date: ${end_date1}`);
    } else {
      console.log('Form is not valid');
    }
  }


  admin() {
    // console.log(this.addLanguageListForm.value)
    this.apiService.getAllAdmin().subscribe((resp: any) => {
      console.log(resp);
      this.allData = resp.getData;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }

  

  onAgentSelect(id) {
    let dataForForm = {
      id: id,

    };

    this.AgentID = id
    console.log(this.AgentID,">>>>");
    
   
  }

  adventure() {
    this.apiService.activeAdvent().subscribe((res: any) => {
      this.allAdve = res.data;
      console.log(this.allAdve)
    });
  }

  financialList(){
    console.log(this.value, this.selectFinancialyear)
    if(this.selectFinancialyear == ''){
      Swal.fire({
        text: 'Please select financial year',
        icon: 'warning',
      });
      return
    }
    this.spinner.show();
    this.apiService.financialYearReportList({name: this.selectFinancialyear}).subscribe((res: any) => {
      this.spinner.hide();
      if(res.success){
        this.allUser = res.getData;
        console.log(res, this.allTour, this.allUser)
        for (let data of this.allUser) {
          data.totalAddon = 0;
          if (data.addons_id) {
            const totalAddon = data.addons_id.addons_data.reduce((total, value) => total + (value.price * value.quantity), 0);
            data.addons_id = totalAddon;
          } else {
            data.addons_id = 0;
          }
        }
        // console.log(data.totalAddon)
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.spinner.hide();
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
      } else {
        Swal.fire({
          text: res.message,
          icon: 'error',
        });
        this.spinner.hide();
      }
    })
  }

  getAllPackageData() {
    this.apiService.getAllPack().subscribe((res: any) => {
      if (res.success) {
        //console.log(res, "pack");
        this.allPackageData = res.data;
        // this.tourAllData.packageType_price = data;
      } else {
        console.error(res);
      }
    });
  }

  getPackage(id: string) {
    if (this.allPackageData?.length > 0 && id !== "") {
      return this.allPackageData.filter((pack: any) => pack._id === id)[0]?.package_type;
    } else {
      return "-"
    }
  }

  activeTour() {
    this.apiService.getTrip().subscribe((res: any) => {
      //console.log(res, 'heeeeeeee');
      this.allTour = res.data;
      // this.dtTrigger.next();
      // this.isDtInitialized = true;
          // this.render = 0;
    });
  }

  onCategorySelect(id) {
    let dataForForm = {
      id: id,
    };
    console.log(dataForForm,'hiiiiiiiiii');
    this.apiService.orderID(dataForForm).subscribe((res: any) => {
      console.log(res.getData, 'devvvvvvvvvvvvvvvvvv');
      this.allOrder = res.getData;
    });
  }

  ontourtypeSelect(value: string) {
    console.log('Selected Value:', value);
    this.tourtype = value;
    
  
    // Jab sirf tour type select ho
    // this.ontourtypeSelectt(this.destination, "Default Name");
    this.tourtypefind()
  }
  
  ondomesticSelect(id: string) {
    console.log('Selected Domestic/International:', id);
    this.domesticd = id;
    this.tourtypefind()
  
   
    
    // if (this.tourtype) {
    //   this.ontourtypeSelectt(this.destination, "Default Name");
    // }
  }

  onAdventureSelect() {
    console.log('Selected Adventure Type:', this.selectedAdventures);
    this.tourtypefind()
    // Agar tour type aur domestic/international select ho chuka hai, tab API call karein
    // if (this.tourtype && this.domesticd) {
    //   this.ontourtypeSelectt(this.destination, "Default Name");
    // }
  }


  tourtypefind() {
    const tourparamenter =  {
      "tourtype":  this.tourtype ,
      "domesticorInternational": this.domesticd ,
      "menumaster_id": this.destination ,
      // "adventure_type": [this.selectedAdventures ? this.selectedAdventures : '']
  }

  if (this.selectedAdventures && this.selectedAdventures.length > 0) {
    tourparamenter["adventure_type"] = [this.selectedAdventures];
  }

   
    console.log(tourparamenter)
    //return
    // this.spinner.show()
    this.apiService.tourNamenew(tourparamenter).subscribe((res: any) => {
      console.log(res)
      if(res.success == true){
        this.showrow = true
        // this.spinner.hide()
        this.allTour = res.data;
      }else{
        // this.spinner.hide()
        Swal.fire({
          text: res.message,
          icon: 'error',
        });
      }
    });
  }

  tour() {
    // if (this.tourtype == undefined) {
    //   Swal.fire({
    //     text: 'Please select tour type',
    //     icon: 'warning',
    //   });
    //   return;
    // }
    const dateSchedule = this.dateSchedule?.nativeElement?.value;
    const status = this.status?.nativeElement?.value;
    const month = this.month?.nativeElement?.value;
    const tourparamenter = {
      tour_type: this.tourtype,
      domesticorInternational:  this.domesticd,
      adventure_type: this.selectedAdventures,
      tour_id: this.allOrder._id ? this.allOrder._id : '',
      travel_date: dateSchedule, 
      payment_status: status ? status : '',
      travel_month: month,
      bookedBy : this.AgentID  || '',
      startDate : this.startDate  || '',
      endDate : this.endDate  || '',
      financialYear: this.selectFinancialyear,
      
    }
    console.log(tourparamenter)
    //return
    this.spinner.show()
    this.apiService.toursearch(tourparamenter).subscribe((res: any) => {
      console.log(res)
      if(res.success == true){
        this.showrow = true
        this.spinner.hide()
        this.allUser = res.userData;
      }else{
        this.spinner.hide()
        Swal.fire({
          text: res.message,
          icon: 'error',
        });
      }
    });
  }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

    userreport() {
    const dateSchedule = this.dateSchedule?.nativeElement?.value;
    const status = this.status?.nativeElement?.value;
    const orderDetails = {
    tour_id: this.allOrder._id ? this.allOrder._id : '',
    travel_date: dateSchedule, 
    payment_status: status ? status : '',
    };
    console.log(orderDetails);
    //return;
    this.spinner.show();
    this.apiService.report(orderDetails).subscribe((res: any) => {
      console.log(res,"sssssssssssssssssssssssssssss");
        if (res.success) {
          this.spinner.hide();
          this.allUser = res.userData;
          this.allUser.forEach(async (timeObj, i) => {
            // timeObj.totalAmount = timeObj.totalAmount?.toFixed(2);
            let totalAddon = 0;
            if(timeObj.addons_id){
              timeObj.addons_id.addons_data.forEach(async addons => {
                  totalAddon += addons.price*addons.quantity
                  // console.log(totalAddon, addons);
                  timeObj.addons_id = totalAddon;
              })
            } else if(!timeObj.addons_id) {
              timeObj.addons_id = 0
            }
          })
          // for(let order of this.allUser) {
          //   if (order?.addons_id && order?.addons_id?.addons_data.length) {
          //     const addonData = order?.addons_id?.addons_data;
          //     for (let addon of addonData) {
          //       if (addon.status === 'unpaid') {
          //         this.totalUnpaidAddon += (addon.price * addon.quantity);
          //       }
          //     }
          //     order.addons_id = this.totalUnpaidAddon;
          //   }
          // }

          // console.log(this.allUser,'heyyyyyy');
          if (this.isDtInitialized) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.isDtInitialized = true;
            this.dtTrigger.next();
          }
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
        } else {
          this.spinner.hide();
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
        }
    });
  }

  desti(){
    this.spinner.show()
    this.apiService.allMenuMatser().subscribe((resp: any) => {
        this.alldesti = resp.data
        console.log(this.alldesti)
        this.spinner.hide()
   });      
  }


  ontourtypeSelectDES(id: any,  name: string) {
    console.log('Selected Name:', name)
    this.destination = name
    if (!id || id === 'undefined' || id === 'null') {
      this.showtour = false;
      return;
    }
    this.spinner.show();
    this.apiService.getmainmenu(id).subscribe(
      (resp: any) => {
        if (resp.success) {
          this.tourData = resp.allData;
          this.showtour = true;
        } else {
          this.showtour = false;
        }
        this.spinner.hide();
      },
      (error) => {
        console.error('API call error:', error);
        this.spinner.hide();
      }
    );

    this.apiService.orderID(id).subscribe((res: any) => {
      this.allDate = res.getData;
      console.log(this.allDate._id);
      this.TourID = this.allDate._id;
    });
  
  }

  updateDataTable(data: any[]) {
    if (this.isDtInitialized) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.clear();
        dtInstance.rows.add(data);
        dtInstance.draw();
      });
    } else {
      this.dtTrigger.next();
      this.isDtInitialized = true;
    }
  }


  ontourtypeSelectt(id: any, name: string) {
    console.log('Selected Name:', id);
    this.destination = id;
  
    // Dynamic request body
    let requestBody: any = {
      menumaster_id: this.destination, // Destination ID
    };

    this.tourtypefind()
  
   
  }
  
}