import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { forEach } from 'jszip';

@Component({
  selector: 'app-travelled-report',
  templateUrl: './travelled-report.component.html',
  styleUrls: ['./travelled-report.component.scss']
})
export class TravelledReportComponent implements OnInit {
  allTour: any;
  allOrder: any = {};
  allUser: any[] = [];
  render: number;
  allPackageData: any;
  FinalBooking: boolean = false;
  showrow: boolean = false;
  dtOptions: any = {};
  private isDtInitialized: boolean = false;
  totalUnpaidAddon = 0;
  public value: boolean = false;
  public selectFinancialyear: any;
  public adventure_type: any;
  public getFinancialYear: Array<any> = [];
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  @ViewChild('dateSchedule') dateSchedule: ElementRef;
  @ViewChild('status') status: ElementRef;
  @ViewChild('month') month: ElementRef;
  @ViewChild('cat') TourId: ElementRef;
  data: any;
  allAdve: any;
  tourtype: any;
  domesticd: any;
  selectedAdventures: any
  totalAmount: number;
  amountPaid: number;
  remainingAmount: number;
  alldesti: any;
  destination: string;
  showtour: boolean;
  tourData: any;
  allData: any;
  AgentID: any;
  dateForm: FormGroup;
  startDate: string | null = null;
  endDate: string | null = null;


  constructor(
    private formBuilder: FormBuilder,
    private fb: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {

    this.dateForm = this.fb.group({
      start_date1: ['', Validators.required],
      end_date1: ['', Validators.required]
    });
    this.dateForm.get('start_date1')?.valueChanges.subscribe((value: string) => {
      this.startDate = value;
      console.log("Start Date Selected:", this.startDate);
    });

    this.dateForm.get('end_date1')?.valueChanges.subscribe((value: string) => {
      this.endDate = value;
      console.log("End Date Selected:", this.endDate);
    });
    var yearsLength = 5;
    this.selectFinancialyear = ""
    this.selectedAdventures = ""
    var currentYear = new Date().getFullYear();
    for(var i = 0; i < 5; i++){
      var next = currentYear+1;
      var year = currentYear + '-' + next.toString().slice(-2);
      // $('#financialYear').append(new Option(year, year));
      this.getFinancialYear.unshift(year)
      currentYear--;
    }
     this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: true,
      pageLength: 100,
      dom: 'Bfrtip',
      buttons: [
        { extend: 'excel'  },
        { extend: 'copy' },
        { extend: 'csv' },
        { extend: 'print' },
        { extend: 'pdfHtml5', footer: true, pageSize: 'A3', }
      ]
      //buttons: ['excel']
    };
    // this.render = 1;
    // this.activeTour();
    this.getAllPackageData();
    this.adventure();
    this.desti();
    this.userreport();
    this.activeTour();
    this.admin();
  }

  adventure() {
    this.apiService.activeAdvent().subscribe((res: any) => {
      this.allAdve = res.data;
      console.log(this.allAdve)
    });
  }

  admin() {
    // console.log(this.addLanguageListForm.value)
    this.apiService.getAllAdmin().subscribe((resp: any) => {
      console.log(resp);
      this.allData = resp.getData;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }

  onAgentSelect(id) {
    let dataForForm = {
      id: id,

    };

    this.AgentID = id
    console.log(this.AgentID,">>>>");
    
   
  }
  financialList(){
    console.log(this.value, this.selectFinancialyear)
    if(this.selectFinancialyear == 'Select Year'){
      Swal.fire({
        text: 'Please select financial year',
        icon: 'warning',
      });
      return
    }
    this.spinner.show();
    this.apiService.financialYearReportList({name: this.selectFinancialyear}).subscribe((res: any) => {
      this.spinner.hide();
      if(res.success){
        this.allUser = res.getData;
        console.log(res, this.allTour, this.allUser)
        for (let data of this.allUser) {
          data.totalAddon = 0;
          if (data.addons_id) {
            const totalAddon = data.addons_id.addons_data.reduce((total, value) => total + (value.price * value.quantity), 0);
            data.addons_id = totalAddon;
          } else {
            data.addons_id = 0;
          }
        }
        // console.log(data.totalAddon)
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.spinner.hide();
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
      } else {
        Swal.fire({
          text: res.message,
          icon: 'error',
        });
        this.spinner.hide();
      }
    })
  }

  getAllPackageData() {
    this.apiService.getAllPack().subscribe((res: any) => {
      if (res.success) {
        //console.log(res, "pack");
        this.allPackageData = res.data;
        // this.tourAllData.packageType_price = data;
      } else {
        console.error(res);
      }
    });
  }

  getPackage(id: string) {
    if (this.allPackageData?.length > 0 && id !== "") {
      return this.allPackageData.filter((pack: any) => pack._id === id)[0]?.package_type;
    } else {
      return "-"
    }
  }

  // activeTour() {
  //   this.apiService.getTrip().subscribe((res: any) => {
  //     //console.log(res, 'heeeeeeee');
  //     this.allTour = res.data;
  //     console.log(this.allTour);
      
  //     // this.dtTrigger.next();
  //     // this.isDtInitialized = true;
  //         // this.render = 0;
  //   });
  // }

  onCategorySelect(id) {
    let dataForForm = {
      id: id,
    };
    console.log(dataForForm,'hiiiiiiiiii');
    this.apiService.orderID(dataForForm).subscribe((res: any) => {
      console.log(res.getData, 'devvvvvvvvvvvvvvvvvv');
      this.allOrder = res.getData;
    });
  }

  ontourtypeSelect(id){
    this.tourtype = id
    this.tourtypefind();
  }

  ondomesticSelect(id){
    this.domesticd = id
    this.tourtypefind();
  }

  tour() {
    const dateSchedule = this.dateSchedule?.nativeElement?.value;
    const status = this.status?.nativeElement?.value;
    const month = this.month?.nativeElement?.value;
    const tourparamenter = {
      tour_type: this.tourtype,
      domesticorInternational: this.domesticd,
      adventure_type: this.selectedAdventures,
      tour_id: this.allOrder._id ? this.allOrder._id : '',
      travel_date: dateSchedule,
      payment_status: status ? status : '',
      travelMonth: month ? month : '',
      financialYear: this.selectFinancialyear,
    };

    this.spinner.show();
    this.apiService.tourbooksearch(tourparamenter).subscribe((res: any) => {
      console.log(res,"sssssssssssssssssssssssssssss");
        if (res.success) {
          this.spinner.hide();
          this.allUser = res.userData;
          console.log(this.allUser,'>>>>>>>>');
          
          this.allUser.forEach(async (timeObj, i) => {
            // timeObj.totalAmount = timeObj.totalAmount?.toFixed(2);
            let totalAddon = 0;
            if(timeObj.addons_id){
              timeObj.addons_id.addons_data.forEach(async addons => {
                  totalAddon += addons.price*addons.quantity
                  // console.log(totalAddon, addons);
                  timeObj.addons_id = totalAddon;
              })
            } else if(!timeObj.addons_id) {
              timeObj.addons_id = 0
            }
          })
          // for(let order of this.allUser) {
          //   if (order?.addons_id && order?.addons_id?.addons_data.length) {
          //     const addonData = order?.addons_id?.addons_data;
          //     for (let addon of addonData) {
          //       if (addon.status === 'unpaid') {
          //         this.totalUnpaidAddon += (addon.price * addon.quantity);
          //       }
          //     }
          //     order.addons_id = this.totalUnpaidAddon;
          //   }
          // }

          // console.log(this.allUser,'heyyyyyy');
          if (this.isDtInitialized) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.isDtInitialized = true;
            this.dtTrigger.next();
          }
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
        } else {
          this.spinner.hide();
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
        }
    });
  }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  desti(){
    this.spinner.show()
    this.apiService.allMenuMatser().subscribe((resp: any) => {
        this.alldesti = resp.data
        console.log(this.alldesti)
        this.spinner.hide()
   });      
  }


  ontourtypeSelectt(id: any,  name: string) {
    console.log('Selected Name:', id)
    this.destination = id
    this.tourtypefind();

    // this.apiService.tourName( this.destination).subscribe((res: any) => {
    //   //console.log(res, 'heeeeeeee');
    //   this.allTour = res.data;
    //   console.log(this.allTour);
      
    //   // this.dtTrigger.next();
    //   // this.isDtInitialized = true;
    //       // this.render = 0;
    // });
  
  }

  tourtypefind() {
    const tourparamenter =  {
      "tourtype":  this.tourtype ,
      "domesticorInternational": this.domesticd ,
      "menumaster_id": this.destination ,
      // "adventure_type": [this.selectedAdventures ? this.selectedAdventures : '']
  }

  if (this.selectedAdventures && this.selectedAdventures.length > 0) {
    tourparamenter["adventure_type"] = [this.selectedAdventures];
  }

   
    console.log(tourparamenter)
    //return
    // this.spinner.show()
    this.apiService.tourNamenew(tourparamenter).subscribe((res: any) => {
      console.log(res)
      if(res.success == true){
        this.showrow = true
        // this.spinner.hide()
        this.allTour = res.data;
      }else{
        // this.spinner.hide()
        Swal.fire({
          text: res.message,
          icon: 'error',
        });
      }
    });
  }

  userreport() {
    const dateSchedule = this.dateSchedule?.nativeElement?.value;
    const status = this.status?.nativeElement?.value;
    const month = this.month?.nativeElement?.value;
    const TourId = this.TourId?.nativeElement?.value;
    const tourparamenter = {
      tour_type: this.tourtype,
      domesticorInternational: this.domesticd,
      adventure_type: this.selectedAdventures,
      tour_id: TourId || '',
      travel_date: dateSchedule,
      payment_status: status || '',
      travelMonth: month || '',
      financialYear: this.selectFinancialyear,
      destination: this.destination,
      bookedBy : this.AgentID || '',
      startDate : this.startDate  || '',
      endDate : this.endDate  || '',
    };
  
    console.log(tourparamenter);
    this.spinner.show();
  
    this.apiService.tourtravelledsearch(tourparamenter).subscribe(async (res: any) => {
      console.log(res, "sssssssssssssssssssssssssssss");
  
      if (res.success) {
        this.spinner.hide();
        this.allUser = Array.isArray(res.userData) ? res.userData : []; // Ensure allUser is always an array
  
        this.totalAmount = 0;
        this.amountPaid = 0;
        this.remainingAmount = 0;
        
        for (const timeObj of this.allUser) {
          console.log("Processing User:", timeObj);
        
          let totalAddon = 0;
        
          if (timeObj.addons_id?.addons_data?.length) {
            for (const addons of timeObj.addons_id.addons_data) {
              totalAddon += addons.price * addons.quantity;
            }
            timeObj.addons_id = totalAddon;
          } else {
            timeObj.addons_id = 0;
          }
        
          const total = (timeObj.totalAmount || 0) + (timeObj.addons_id || 0);
          this.totalAmount += total;
          this.amountPaid += timeObj.amountPaid || 0;
          if (timeObj.payment_status?.trim().toLowerCase() === "cancelled") {
            console.log(`❌ Payment Cancelled for: ${timeObj._id || 'unknown'}`);
            continue; 
          }
        
          this.remainingAmount += total - (timeObj.amountPaid || 0);
        }
        
     
        // console.log("🔹 Final Totals:", {
        //   totalAmount: this.totalAmount,
        //   amountPaid: this.amountPaid,
        //   remainingAmount: this.remainingAmount // Should be 0 for cancelled orders
        // });
        
        
        // DataTables initialization
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
  
        Swal.fire({
          text: res.message,
          icon: 'success',
        });
      } else {
        this.allUser = [];
        this.spinner.hide();
        Swal.fire({
          text: res.message,
          icon: 'error',
        });
      }
    });
  }


  activeTour() {
    this.apiService.getTrip().subscribe((res: any) => {
      //console.log(res, 'heeeeeeee');
      this.allTour = res.data;
      // this.dtTrigger.next();
      // this.isDtInitialized = true;
          // this.render = 0;
    });
  }


  
  

  
  
  
  
}